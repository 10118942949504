<template>
    <div>
        <div class="row">
            <div class="col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div v-for="(state, index) in states" :key="index">
                            <div class="row pointer" @click="activeState = state">
                                <div class="col align-self-center">
                                    <p class="mb-0 f-14" :class="state == activeState ? 'font-600' : 'font-400'">{{ state }}</p>
                                </div>
                                <div class="col-2">
                                    <span class="material-icons">arrow_forward_ios</span>
                                </div>
                            </div>
                            <hr v-if="index != states.length-1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <section v-if="activeState == 'Logo'">
                            <p><u><b>Logo</b></u></p>
                            <Logo/>
                        </section>
                        <section v-if="activeState == 'Carousel'">
                            <p><u><b>Carousel</b></u></p>
                            <Carousel/>
                        </section>
                        <section v-if="activeState == 'Banner Home'">
                            <p><u><b>Banner Home</b></u></p>
                            <BannerHome/>
                        </section>
                        <section v-if="activeState == 'Banner Pricelist'">
                            <p><u><b>Banner Pricelist</b></u></p>
                            <BannerPricelist/>
                        </section>
                        <section v-if="activeState == 'Tentang Kami'">
                            <p><u><b>Tentang Kami</b></u></p>
                            <AboutUs/>
                        </section>
                        <section v-if="activeState == 'Social Media'">
                            <p><u><b>Social Media</b></u></p>
                            <SocialMedia/>
                        </section>
                        <section v-if="activeState == 'FAQ'">
                            <p><u><b>FAQ</b></u></p>
                            <Faq/>
                        </section>
                        <section v-if="activeState == 'Payment Method'">
                            <p><u><b>Payment Method</b></u></p>
                            <PaymentMethod/>
                        </section>
                        <section v-if="activeState == 'Footer'">
                            <p><u><b>Footer</b></u></p>
                            <Footer/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Carousel from '@/views/pengaturan/Carousel'
import Logo from '@/views/pengaturan/Logo'
import BannerHome from '@/views/pengaturan/BannerHome'
import BannerPricelist from '@/views/pengaturan/BannerPricelist'
import AboutUs from '@/views/pengaturan/AboutUs'
import SocialMedia from '@/views/pengaturan/SocialMedia'
import Faq from '@/views/pengaturan/Faq'
import PaymentMethod from '@/views/pengaturan/PaymentMethod'
import Footer from '@/views/pengaturan/Footer'

export default {
    components: {
        Carousel,
        Logo,
        BannerHome,
        BannerPricelist,
        AboutUs,
        SocialMedia,
        Faq,
        PaymentMethod,
        Footer,
    },
    data() {
        return {
            states: [
                'Logo',
                'Carousel',
                'Banner Home',
                'Banner Pricelist',
                'Tentang Kami',
                'Social Media',
                'FAQ',
                'Payment Method',
                'Footer'
            ],
            activeState: 'Logo'
        }
    }
}
</script>