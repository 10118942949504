<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/produk-online/add')">
                                    <i class="fa fa-plus"></i> Tambah
                                </button>&nbsp;
                                <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#setHargaRetail" v-can="'spv'">
                                    <i class="fa fa-gear"></i> Set Harga Acuan Retail
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Cari berdasarkan kode/nama...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/produk-online/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
        
        <div class="modal fade text-left show" id="setHargaRetail" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="saveHargaAcuan()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Harga Acuan Retail</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group">
                                                <label for="">Harga Dasar</label>
                                                <vue-numeric v-model="set_harga_acuan.price" class="form-control" placeholder="Masukkan harga" currency="Rp" separator="."></vue-numeric>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>

                                        <div class="form-group">
                                            <label for="">Harga Grosir</label>
                                            <hr>
                                            <div class="" v-for="(price, index) in set_harga_acuan.prices" :key="index">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group mb-0">
                                                            <label for="">Jumlah Minimal</label>
                                                            <vue-numeric class="form-control" placeholder="Masukkan Jumlah Minimal" currency="Diamond" separator="."
                                                                v-model="price.diamond_min" currency-symbol-position="suffix">
                                                            </vue-numeric>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group mb-0">
                                                            <label>Harga</label>
                                                            <vue-numeric v-model="price.diamond_price" class="form-control" placeholder="Masukkan Harga" currency="Rp" separator="."></vue-numeric>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-danger btn-sm align-self-end mr-1" style="height: 38px;" 
                                                        title="Hapus Harga Grosir" type="button" @click="remove_harga_grosir(index)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                                <hr>
                                            </div>
                                            <button class="btn btn-primary btn-sm" type="button" 
                                                @click="add_harga_grosir()">
                                                <i class="fa fa-plus"></i> Tambah Harga Grosir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="set_harga_acuan.price = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Produk Online',
                dataTable: {
                    header: ['#', 'Foto', 'Nama', 'Jumlah Diamond', 'Jumlah Diamond / 1 Poin', 'Harga', 'Aksi'],
                    columns: ['counter', 'photo', 'name', 'diamond', 'point', 'price'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            set_harga_acuan: {
                price: 0,
                prices: []
            }
		}
	},
    mounted() {
        let _ = this
        _.getHargaAcuan()
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getHargaAcuan() {
            let _ = this
            _.axios.get('/harga_acuan_retail')
                .then( resp => {
                    _.set_harga_acuan.price = resp.data.data.price
                    _.set_harga_acuan.prices = resp.data.data.prices
                })
        },
        add_harga_grosir() {
            let _ = this
            _.set_harga_acuan.prices.push({
                diamond_min: 0,
                diamond_price: 0
            })
        },
        remove_harga_grosir(index) {
            let _ = this
            _.set_harga_acuan.prices.splice(index, 1)
        },
        saveHargaAcuan() {
            let _ = this
            let data = {
                price: _.set_harga_acuan.price,
                prices: JSON.stringify(_.set_harga_acuan.prices)
            }
            _.axios.post('/harga_acuan_retail', data)
                .then(resp=>{
                    _.set_harga_acuan.price = resp.data.data.price
                    window.$("#setHargaRetail .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getList(page) {
            let _ = this
            _.axios.get('/online_product?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Produk?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/online_product/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>