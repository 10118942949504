<template>
	<div>
        <h3 class="font-600 mb-1">{{ title }}</h3>
        <slot name="header"></slot>
        <div class="table-responsive">
            <table class="table mb-0">
                <slot name="thead"></slot>
                <tbody>
                    <tr v-for="(data, key) in dataTable.lists.data" :key="key">
                        <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">
                            <span v-if="(column == 'counter')">
                                {{ (dataTable.lists.current_page - 1) * dataTable.lists.per_page + key + 1 }}
                            </span>
                            <span v-else-if="column == 'created_at' || column == 'updated_at'">
                                {{ data[column] | moment("DD/MM/YYYY, HH:mm") }}
                            </span>
                            <span v-else-if="column == 'photo'">
                                <img v-if="data[column] != null && !isVideo(data[column])" :src="data[column]" class="img-fluid pointer rounded" style="width: 50px; height: 50px;object-fit:cover;" @click="lihat(data[column])"> 
                                <span v-else>{{data[column]}}</span>
                            </span> 
                            <span v-else-if="column == 'image'">
                                <img v-if="data[column] != null && !isVideo(data[column])" :src="data[column]" class="img-fluid pointer rounded" style="width: 50px; height: 50px;object-fit:cover;" @click="lihat(data[column])"> 
                                <span v-else>
                                    <a :href="data[column]" class="" target="_blank">Video</a>
                                </span>
                            </span> 
                            <span v-else-if="column == 'photos'">
                                <img v-if="data[column] != null" :src="data[column][0]" class="img-fluid pointer rounded" style="width: 50px; height: 50px;object-fit:cover;" @click="lihat(data[column][0])"> 
                            </span> 
                            <span v-else-if="column == 'description'">
                                {{ data[column] && html_striptag(data[column]).length > 100 ? html_striptag(data[column]).substring(0, 100) + '...' : html_striptag(data[column])}}
                            </span>
                            <span v-else-if="column == 'diamond' || column == 'total_point' || column == 'total_diamond' || column == 'stock'">
                                {{ data[column] | numFormat }}
                            </span>
                            <span v-else-if="column == 'price_point'">
                                {{ data['price'] | numFormat }} Point
                            </span>
                            <span v-else-if="column == 'point'">
                                {{ data[column] }} Diamond
                            </span>
                            <span v-else-if="column == 'whatsapp_url'">
                                <a :href="data[column]" target="_blank">{{ data[column] }}</a> 
                            </span>
                            <span v-else-if="column == 'slug'">
                                <a :href="'https://mrtopup.id/landing/' + data[column]" target="_blank">https://mrtopup.id/landing/{{ data[column] }}</a> 
                            </span>
                            <span v-else-if="column == 'is_primary'">
                                {{ data[column] == 1 ? 'Yes' : 'No' }}
                            </span>
                            <span v-else-if="column == 'is_active'">
                                {{ data[column] == 1 ? 'Aktif' : 'Tidak Aktif' }}
                            </span>
                            <span v-else-if="column == 'price' || column == 'total_price' || column == 'commission' || column == 'price_rupiah' || column == 'bonus_jaringan'">
                                {{ data[column] | currency }}
                            </span>
                            <span v-else-if="column == 'transaction_status' || column == 'payment_status'">
                                <button class="btn btn-sm" :class="status_color(data[column])">{{ status_name(data[column], column) }}</button>
                            </span>
                            <span v-else v-html="data[column] == null ? '-' : data[column]"></span>
                        </td>
                        <td>
                            <slot name="action" v-bind:data="data"></slot>
                        </td>
                    </tr>
                </tbody>           
            </table>
            <div class="col text-center mt-1">
                <p class="f-14" v-if="dataTable.lists.total == 0 || !dataTable.lists.data">Tidak ada data</p>
            </div>                                                                           
        </div>
        <PaginateList :data="dataTable.lists" v-if="dataTable.lists.total != 0"/>
	</div>
</template>

<script>
import PaginateList from '@/components/PaginateList'

export default {
    props: [ 'title', 'dataTable' ],
    components: {
        PaginateList
    },
    methods: {
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
        status_color( status ){
            let badge = ''
            switch (status) {
                case 'pending':
                    badge = "btn-warning"
                    break;
                case 'success':
                    badge = "btn-success"
                    break;
                case 'failed':
                    badge = "btn-danger"
                    break;
                case 'expire':
                    badge = "btn-danger"
                    break;
                case 'cancel':
                    badge = "btn-danger"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        status_name( status, column ){
            let badge = ''
            switch (status) {
                case 'pending':
                    badge = column == 'payment_status' ? "Menunggu Pembayaran" : 'Menunggu Konfirmasi'
                    break;
                case 'success':
                    badge = "Berhasil"
                    break;
                case 'failed':
                    badge = "Gagal"
                    break;
                case 'expire':
                    badge = "Kadaluarsa"
                    break;
                case 'cancel':
                    badge = "Dibatalkan"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        html_striptag(str) {
            if (str) {
                return str.replace(/(<([^>]+)>)/gi, "")
            }

            return '-'
        },
        isVideo( link ) {
            const videos = ["mp4", "3gp", "ogg"]

            const url = new URL( link )
            const extension = url.pathname.split(".")[1]

            if (videos.includes(extension)) {
                return true
            }

            return false
        }
    },
    mounted() {
        this.$moment.locale('id')
    }
}
</script>