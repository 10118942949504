<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/agen/add')" v-can="'admin'">
                                    <i class="fa fa-plus"></i> Tambah
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Cari berdasarkan kode/nama...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/agen/detail/' + data.id">
                            <button type="button" class="btn btn-rounded btn-info waves-effect waves-light" title="Edit" v-can="['admin', 'spv']">
                                <i class="fa fa-users"></i>
                            </button>
                        </router-link>
                        <router-link :to="'/agen/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit" v-can="'spv'">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)" v-can="'spv'">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Agen',
                dataTable: {
                    header: ['#', 'Kode', 'Nama', 'No. Telepon', 'Komisi / Diamond', 'Bonus Jaringan', 'Admin', 'Status', 'Aksi'],
                    columns: ['counter', 'code', 'name', 'phone_number', 'commission', 'bonus_jaringan', 'admin_detail', 'is_active'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/agent?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Agen?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/agent/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>