<template>
    <div>
        <ValidationObserver class="card-content" ref="observer">
            <form @submit.prevent="save()">
                <div class="form-group w-50">
                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="required">
                        <label for="">Deskripsi</label>
                        <textarea cols="30" rows="5" v-model="model.about_us.description" class="form-control"></textarea>
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <div class="form-group w-50">
                    <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required">
                        <label for="">Alamat</label>
                        <textarea cols="30" rows="5" v-model="model.about_us.address" class="form-control"></textarea>
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <div class="form-group w-50">
                    <ValidationProvider name="No. Telp" v-slot="{ errors }" rules="required">
                        <label for="">No. Telp</label>
                        <input type="text" class="form-control" v-model="model.about_us.phone_number">
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <hr>
                <div class="text-right mt-1">
                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Simpan</div>
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            model: {
                about_us: {
                    description: '',
                    address: '',
                    phone_number: '',
                }
            }
        }
    },
    mounted() {
        let _ = this
        _.index()
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/setting')
                .then(resp=>{
                    if ( resp.data.data.about_us ) {
                        _.model.about_us = resp.data.data.about_us   
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    about_us: JSON.stringify(_.model.about_us)
                }

                _.axios.post('/setting', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    }
}
</script>