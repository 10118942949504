<template>
    <div>
        <Table 
            :dataTable="table.dataTable"
            :title="table.title">
            <template v-slot:header>
                <div class="row">
                    <div class="col-lg-8 col-xs-12">
                        <div class="btn-responsive">
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modal" @click="reset()">
                                <i class="fa fa-plus"></i> Tambah
                            </button>
                        </div>
                    </div>
                    <div class="col mb-1">
                        <div class="input-group form-search-rounded">
                            <input class="form-control" v-model="search" type="search"
                                placeholder="Cari berdasarkan judul...">
                            <span class="input-group-append">
                                <div class="input-group-text bg-transparent">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>                
            </template>

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit" 
                        data-toggle="modal" data-target="#modal" @click="model = data">
                        <i class="fa fa-edit"></i>
                    </button>
                    <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template> 
        </Table>

        <div class="modal fade text-left show" id="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">{{ model.id ? 'Edit' : 'Tambah' }} FAQ</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <ValidationProvider name="Pertanyaan" v-slot="{ errors }" rules="">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Pertanyaan</label>
                                        <input type="text" v-model="model.question" class="form-control" placeholder="Masukkan pertanyaan">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Jawaban" v-slot="{ errors }" rules="">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Jawaban</label>
                                        <quill-editor v-model="model.answer" :options="editorOption"/>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="order" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Urutan</label>
                                        <input type="number" v-model="model.order" class="form-control" placeholder="Masukkan urutan">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <div class="text-right mt-1">
                                    <button class="btn text-danger" data-dismiss="modal">Batal</button>
                                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                                        <div v-else>Simpan</div>
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
    components: {
        Table
	},
    data() {
        return {
            table: {
                title: '',
                dataTable: {
                    header: ['#', 'Urutan', 'Pertanyaan', 'Jawaban', 'Aksi'],
                    columns: ['counter', 'order', 'question', 'answer'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            model: {
                order: 1,
                question: '',
                answer: '',
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link']
                    ]
                }
            },
        }
    },
    mounted() {
        let _ = this
        _.index()
        _.index = debounce(_.index, 500)

        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/faq?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus data ini?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/faq/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.index()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        reset() {
            this.model = {
                order: 1,
                question: '',
                answer: '',
            }
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.model
                }

                _.axios.post('/faq' + (_.model.id ? '/' + _.model.id : ''), data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        window.$('.close').click()
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>