<template>
    <div>
        <ValidationObserver class="card-content" ref="observer">
            <form @submit.prevent="save()">
                <div class="form-group">
                    <ValidationProvider name="Footer" v-slot="{ errors }" rules="required">
                        <label for="">Footer</label>
                        <input type="text" class="form-control" v-model="model.footer">
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <hr>
                <div class="text-right mt-1">
                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Simpan</div>
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            model: {
                footer: ''
            }
        }
    },
    mounted() {
        let _ = this
        _.index()
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/setting')
                .then(resp=>{
                    _.model.footer = resp.data.data.footer
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.model
                }

                _.axios.post('/setting', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    }
}
</script>