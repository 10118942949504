<template>
    <div class="card col-lg-12 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Event</h3>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="Foto" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Foto</label>
                            <div class="text-center profile-avatar" v-if="model.image">
                                <img :src="model.image" class="img-fluid rounded mt-1 mr-1 pointer" 
                                    alt="" style="width: 107px; height: 108px;"><br>
                                <div class="edit-avatar">
                                    <button 
                                        @click="model.image = null"
                                        class="btn btn-rounded btn-danger" 
                                        title="Delete"
                                        type="button">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>

                            <label for="file-upload" class="pointer" v-if="!model.image">
                                <img src="/assets/img/upload_image.png" alt="" class="img-fluid mt-1">
                            </label>
                            <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)" v-if="!model.image">
                            
                            <input type="hidden" v-model="model.image">
                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="title" v-slot="{ errors }" rules="">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Judul</label>
                            <input type="text" v-model="model.title" class="form-control" placeholder="Masukkan judul event">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="Deskripsi" v-slot="{ errors }" rules="">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Deskripsi</label>
                            <quill-editor v-model="model.description" :options="editorOption"/>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-right mt-1">
                        <router-link to="/banner" class="btn text-danger">Batal</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                image: null,
                title: '',
                description: ''
            },
            isLoading: false,
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link']
                    ]
                }
            },
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/event/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.model
                }

                _.axios.post('/event' + (_.$route.params.id ? '/' + _.$route.params.id : ''), data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/event')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.image = resp.data.data.url
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }
    }
}
</script>