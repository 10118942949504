<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Penjualan</h3>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="customer" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Customer</label>
                            <vue-autosuggest
                                v-model					= model.customer_code
                                :suggestions			= options.customers
                                :input-props			="{class: 'form-control', placeholder:'Cari nama/kode customer'}"
                                @selected				= selectedCustomer
                                @input					= get_customers>
                                <template slot-scope="{suggestion}">
                                    <div class="row">
                                        <span class="my-suggestion-item col m-0 align-self-center">{{suggestion.item.code}}</span>
                                        <span class="my-suggestion-item col m-0 align-self-center">{{suggestion.item.name}}</span>
                                    </div>								
                                </template>
                            </vue-autosuggest>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <input type="checkbox" name="" id="" class="mb-1" v-model="model.isAgen" @change="selectedAgent = null"> Agen
                    <div class="row" v-if="model.isAgen">
                        <div class="col">
                            <ValidationProvider name="customer" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label>Agen</label>
                                    <multiselect 
                                        v-model="selectedAgent"
                                        :custom-label="customLabel"
                                        track-by="code" 
                                        placeholder="Cari nama/kode agen"
                                        :options="options.agents" 
                                        :multiple="false" 
                                        :searchable="true" 
                                        :loading="isLoading" 
                                        :internal-search="false" 
                                        :clear-on-select="false" 
                                        :close-on-select="true" 
                                        :max-height="600" 
                                        :show-no-results="false" 
                                        :hide-selected="false" 
                                        @search-change="get_agents">
                                        <span slot="noResult">Oops! Data agen tidak ditemukan.</span>
                                    </multiselect>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col-4 d-none">
                            <ValidationProvider name="commission" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Komisi</label>
                                    <vue-numeric v-model="model.commission" class="form-control" placeholder="Masukkan komisi" currency="Rp" separator="."></vue-numeric>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Jenis Produk</label>
                            <select class="form-control" v-model="model.isManual" @change="selectedProduct = null">
                                <option value="1">Manual</option>
                                <option value="0">Paket</option>
                            </select>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="produk" v-slot="{ errors }" v-if="model.isManual == '0'">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Produk</label>
                            <multiselect 
                                v-model="selectedProduct"
                                track-by="id" 
                                label="name"
                                placeholder="Cari nama/kode produk"
                                :options="options.products" 
                                :multiple="false" 
                                :searchable="true" 
                                :loading="isLoading" 
                                :internal-search="false" 
                                :clear-on-select="false" 
                                :close-on-select="true" 
                                :max-height="600" 
                                :show-no-results="false" 
                                :hide-selected="false" 
                                @search-change="get_products">
                                <span slot="noResult">Oops! Data produk tidak ditemukan.</span>
                            </multiselect>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <div class="col">
                            <ValidationProvider name="poin" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Jumlah Diamond</label>
                                    <vue-numeric v-model="model.total_diamond" class="form-control" placeholder="Masukkan diamond" currency="" separator="." :disabled="model.isManual == '0'"></vue-numeric>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="col">
                            <ValidationProvider name="harga" v-slot="{ errors }" rules="required">
                                <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                    <label>Harga</label>
                                    <vue-numeric v-model="model.total_price" class="form-control" placeholder="Masukkan harga" currency="Rp" separator="." :disabled="model.isManual == '0'"></vue-numeric>
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <ValidationProvider name="keterangan" v-slot="{ errors }">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Keterangan</label>
                            <textarea name="" id="" cols="30" rows="3" class="form-control" v-model="model.description"></textarea>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-right mt-1">
                        <router-link to="/penjualan" class="btn text-danger">Batal</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                isAgen: false,
                isManual: "0",
                id_customer: null,
                id_agent: null,
                id_product: null,
                commission: 0,
                customer_code: ""
            },
            isLoading: false,
            options: {
                customers: [{
                    data: []
                }],
                products: [],
                agents: []
            },
            selectedAgent: "",
            selectedProduct: "",
            harga_acuan: 0
        }
    },
    methods: {
        customLabel({ code }) {
            return `${code}`;
        },
        formattedDisplay(result) {
		    return result.item.code
		},
        get() {
            let _ = this
            _.axios.get('/transaction/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        get_customers(key) {
            let _ = this
            _.axios.get('/customer?key='+ (key ? key : '') + '&limit=10&page=1')
                .then(resp => {
                    _.options.customers[0].data = resp.data.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        get_agents(key) {
            let _ = this
            _.axios.get('/agent?key='+ (key ? key : '') + '&limit=10&page=1&is_active=1')
                .then(resp => {
                    _.options.agents = resp.data.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        get_products(key) {
            let _ = this
            _.axios.get('/product?key='+ (key ? key : '') + '&limit=10&page=1')
                .then(resp => {
                    _.options.products = resp.data.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/transaction' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/penjualan')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
        get_harga_acuan(){
            let _ = this
            _.axios.get('/harga_acuan')
                .then(resp=>{
                    _.harga_acuan = resp.data.data.price
                })
        },
        selectedCustomer(result) {
            let _ = this
			let item = result.item
            _.model.customer_code = item.code
        }
    },
    mounted() {
        let _ = this
        _.get_customers()
        _.get_agents()
        _.get_products()
        _.get_harga_acuan()

        if (_.$route.params.id){
            _.get()
        }
    },
    watch: {
        selectedAgent: function(){
            let _ = this
            if (_.selectedAgent == null) {
                _.model.id_agent = null
            } else {
                _.model.id_agent = _.selectedAgent.id
            }
        },
        selectedProduct: function(){
            let _ = this
            if (_.selectedProduct == null) {
                _.model.id_product = null
                _.model.total_diamond = 0
                _.model.total_price = 0
            } else {
                _.model.id_product = _.selectedProduct.id
                _.model.total_diamond = _.selectedProduct.diamond
                _.model.total_price = _.selectedProduct.price
            }
        },
        'model.total_diamond': function() {
            let _ = this
            if (_.model.isAgen == true) {
                _.model.commission = _.model.total_diamond * _.selectedAgent.commission
            }

            if (_.model.isManual == 1) {
                _.model.total_price = _.model.total_diamond * _.harga_acuan
            }
        }
    }
}
</script>