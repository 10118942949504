<template>
    <div>
        <div class="row">
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-1">Detail Transaksi</h3>
                        <label for="">Order ID</label>
                        <p class="font-600">{{ transaction.order_id }}</p>
                        <label for="">Tanggal Dibuat</label>
                        <p class="font-600">{{ transaction.created_at | moment('DD MMMM YYYY - HH:mm') }} WIB</p>
                        <label for="">Metode Pembayaran</label>
                        <p class="font-600">{{ transaction.payment_method == 'cc' ? 'Kartu Kredit' : 'Point' }}</p>
                        <label for="">Status Pembayaran</label>
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm mb-1" disabled :class="payment_status_color(transaction.payment_status)" 
                                title="Status Pembayaran">
                                {{ payment_status(transaction.payment_status) }}
                            </button>
                        </div>
                        <label for="">Status Transaksi</label>
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm dropdown-toggle mb-1" :disabled="transaction.payment_status == 'pending' || transaction.status == 'done'" :class="transaction_status_color(transaction.status)" 
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Ubah Status Transaksi">
                                {{ transaction_status(transaction.status) }}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" @click="change_transaction_status('packing')" v-if="transaction.status == 'pending'">Dikemas</a>
                                <a class="dropdown-item" data-toggle="modal" data-target="#modalInputShipping" v-if="transaction.status == 'packing'">Dalam Pengiriman</a>
                                <a class="dropdown-item" @click="change_transaction_status('done')" v-if="transaction.status == 'on_delivery'">Selesai</a>
                                <a class="dropdown-item" @click="change_transaction_status('rejected')" v-if="transaction.status == 'pending'">Ditolak</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-1">Detail Produk</h3>
                        <label for="">Nama Produk</label>
                        <p class="font-600">{{ transaction.merchandise_name }}</p>
                        <label for="">Harga</label>
                        <p class="font-600" v-if="transaction.payment_method == 'cc'">{{ transaction.price | currency }}</p>
                        <p class="font-600" v-if="transaction.payment_method == 'point'">{{ transaction.price | numFormat }} Point</p>
                        <label for="">Jumlah</label>
                        <p class="font-600">{{ transaction.quantity | numFormat }} Item</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-1">Detail Customer</h3>
                        <label for="">Nama Customer</label>
                        <p class="font-600">{{ transaction.customer_name }} </p>
                        <label for="">No. Telp Customer</label>
                        <p class="font-600">{{ transaction.customer_phone }} </p>
                        <label for="">Alamat Customer</label>
                        <p class="font-600">{{ transaction.customer_address }} </p>
                    </div>
                </div>

                <div class="card" v-if="transaction.status != 'pending' && transaction.status != 'packing' && transaction.status != 'rejected'">
                    <div class="card-body">
                        <h3 class="font-600 mb-1">Detail Pengiriman</h3>
                        <label for="">Jasa Pengiriman</label>
                        <p class="font-600">{{ transaction.shipping_method ? transaction.shipping_method : '-' }} </p>
                        <label for="">No. Resi</label>
                        <p class="font-600">{{ transaction.shipping_no ? transaction.shipping_no : '-' }} </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="modalInputShipping" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-md modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Detail Pengiriman</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Jasa Pengiriman</label>
                            <input type="text" class="form-control" placeholder="ex: JNE, JNT" v-model="shippingDetail.shipping_method">
                        </div>
                        <div class="form-group">
                            <label for="">No. Resi</label>
                            <input type="text" class="form-control" placeholder="ex: 1301127365123234" v-model="shippingDetail.shipping_no">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="kirimBarang()">Kirim Barang</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            transaction: {},
            shippingDetail: {
                shipping_method: '',
                shipping_no: ''
            }
        }
    },
    methods: {
        get()
        {
            let _ = this
            _.axios.get('/transaction_merchandise/' + _.$route.params.id)
                .then(resp => {
                    _.transaction = resp.data.data
                })
        },
        payment_status_color(status) {
            let badge = ''
            switch (status) {
                case 'pending':
                    badge = "btn-warning"
                    break;
                case 'success':
                    badge = "btn-success"
                    break;
                case 'failed':
                    badge = "btn-danger"
                    break;
                case 'expire':
                    badge = "btn-danger"
                    break;
                case 'cancel':
                    badge = "btn-danger"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        payment_status(status) {
            let badge = ''
            switch (status) {
                case 'pending':
                    badge = "Menunggu Pembayaran"
                    break;
                case 'success':
                    badge = "Berhasil"
                    break;
                case 'failed':
                    badge = "Gagal"
                    break;
                case 'expire':
                    badge = "Kadaluarsa"
                    break;
                case 'cancel':
                    badge = "Dibatalkan"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        change_payment_status(status) {
            let _ = this

            if ( _.transaction.payment_status != 'pending' ) {
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: 'Status pembayaran sudah tidak bisa diubah'
                })
                return;
            }

            _.axios.post('/transaction_merchandise/' + _.$route.params.id, { payment_status: status })
                .then(() => {
                    if (status != 'success') {
                        _.change_transaction_status('failed')
                        return;
                    }
                    _.get()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        transaction_status_color(status) {
            let badge = ''

            switch (status) {
                case 'pending':
                    badge = "btn-warning"
                    break;
                case 'packing':
                    badge = "btn-outline-primary"
                    break;
                case 'on_delivery':
                    badge = "btn-primary"
                    break;
                case 'done':
                    badge = "btn-success"
                    break;
                case 'rejected':
                    badge = "btn-danger"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        transaction_status(status) {
            let badge = ''
            switch (status) {
                case 'pending':
                    badge = "Menunggu Konfirmasi"
                    break;
                case 'packing':
                    badge = "Dikemas"
                    break;
                case 'on_delivery':
                    badge = "Dalam Pengiriman"
                    break;
                case 'done':
                    badge = "Selesai"
                    break;
                case 'rejected':
                    badge = "Ditolak"
                    break;
            
                default:
                    break;
            }

            return badge
        },
        change_transaction_status(status) {
            let _ = this

            if ( _.transaction.transaction_status == 'rejected' ) {
                _.$notify({
                    type: 'error',
                    title: 'Error!',
                    text: 'Status transaksi sudah tidak bisa diubah'
                })
                return;
            }

            _.axios.post('/transaction_merchandise/' + _.$route.params.id, { status: status })
                .then(() => {
                    _.get()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        kirimBarang()
        {
            let _ = this
            _.axios.post('/transaction_merchandise/' + _.$route.params.id, _.shippingDetail)
                .then(() => {
                    window.$('#closeModal').click()
                    _.change_transaction_status('on_delivery')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        }        
    },
    mounted() {
        let _ = this

        _.get()
    },
}
</script>