<template>
    <div >
        <div >
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12 mb-1">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#filter_penjualan">
                                    <i class="fa fa-filter"></i> Filter
                                </button> &nbsp;
                                <button type="button" class="btn btn-outline-primary" @click="download()">
                                    <i class="fa fa-download"></i> Download
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded d-none">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Cari berdasarkan kode/nama...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th class="text-center font-600">#</th>
                            <th class="font-600 no-wrap" @click="sortBy('created_at')">
                                Waktu<br>Transaksi
                                <i class="fa sort" 
                                    :class="currentSort == 'created_at' ? 
                                    currentSortDir == 'asc' ? 
                                        'fa-sort-down' : 
                                    currentSortDir == 'desc' ? 
                                        'fa-sort-up' : 'fa-sort' : 'fa-sort'">
                                </i>
                            </th>
                            <th class="text-center font-600 no-wrap" @click="sortBy('total_diamond')">
                                Diamond
                                <i class="fa sort" 
                                    :class="currentSort == 'total_diamond' ? 
                                    currentSortDir == 'asc' ? 
                                        'fa-sort-down' : 
                                    currentSortDir == 'desc' ? 
                                        'fa-sort-up' : 'fa-sort' : 'fa-sort'">
                                </i>
                            </th>
                            <th class="text-center font-600 no-wrap" @click="sortBy('id_admin')">
                                Kode Admin
                                <i class="fa sort" 
                                    :class="currentSort == 'id_admin' ? 
                                    currentSortDir == 'asc' ? 
                                        'fa-sort-down' : 
                                    currentSortDir == 'desc' ? 
                                        'fa-sort-up' : 'fa-sort' : 'fa-sort'">
                                </i>
                            </th>
                            <th class="text-center font-600 no-wrap" @click="sortBy('id_agent')">
                                Kode Agen
                                <i class="fa sort" 
                                    :class="currentSort == 'id_agent' ? 
                                    currentSortDir == 'asc' ? 
                                        'fa-sort-down' : 
                                    currentSortDir == 'desc' ? 
                                        'fa-sort-up' : 'fa-sort' : 'fa-sort'">
                                </i>
                            </th>                                                      
                            <th class="text-center font-600">Aksi</th>
                        </tr>
                    </thead>
                </template>
            </Table>

            <hr>
            <p class="mb-0">Total Diamond Terjual: <b>{{ totalDiamond.total_diamond | numFormat}} Diamond</b></p>
        </div>
        <div class="modal fade text-left show" id="filter_penjualan" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Filter Penjualan</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="filter_by">Filter Berdasarkan</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-if="f.by == 'tgl_awal'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Awal</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_awal"
                                                    format="DD MMMM, YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal awal penjualan"  
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'tgl_akhir'">
                                        <div class="form-group">
                                            <label for="filter_by">Tanggal Akhir</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.tgl_akhir"
                                                    format="DD MMMM, YYYY"
                                                    type="date"
                                                    value-type="YYYY-MM-DD"
                                                    placeholder="Tanggal akhir penjualan"   
                                                    style="width: 100%"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_customer'">
                                        <div class="form-group">
                                            <label>ID Bigo</label>
                                            <!-- <select name="" id="" class="form-control" v-model="filterby.id_customer">
                                                <option value="">Pilih Customer</option>
                                                <option v-for="(data, index) in options.customers" :key="index" :value="data.id">
                                                    {{data.code}} | {{data.name}}
                                                </option>
                                            </select> -->

                                            <multiselect 
                                                v-model="selectedCustomer"
                                                :custom-label="customLabel"
                                                track-by="code" 
                                                :placeholder="'Search ID Bigo/Name of Customer'"
                                                open-direction="bottom" 
                                                :options="options.customers" 
                                                :multiple="false" 
                                                :searchable="true" 
                                                :loading="isLoading" 
                                                :internal-search="false" 
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :max-height="600" 
                                                :show-no-results="false" 
                                                :hide-selected="false" 
                                                @search-change="getCustomer">
                                                <span slot="noResult">Oops! No Data found.</span>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_product'">
                                        <div class="form-group">
                                            <label>Produk</label>
                                            <!-- <select name="" id="" class="form-control" v-model="filterby.id_product">
                                                <option :value="null">Manual</option>
                                                <option v-for="(data, index) in options.products" :key="index" :value="data.id">
                                                    {{data.name}}
                                                </option>
                                            </select> -->

                                            <multiselect 
                                                v-model="selectedProduct"
                                                label="name"
                                                track-by="id" 
                                                :placeholder="'Search Name of Product'"
                                                open-direction="bottom" 
                                                :options="options.products" 
                                                :multiple="false" 
                                                :searchable="true" 
                                                :loading="isLoading" 
                                                :internal-search="false" 
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :max-height="600" 
                                                :show-no-results="false" 
                                                :hide-selected="false" 
                                                @search-change="getProduct">
                                                <span slot="noResult">Oops! No Data found.</span>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_admin'">
                                        <div class="form-group">
                                            <label>Admin</label>
                                            <!-- <select name="" id="" class="form-control" v-model="filterby.id_admin">
                                                <option value="">Pilih Admin</option>
                                                <option v-for="(data, index) in options.admins" :key="index" :value="data.id">
                                                    {{data.code}} | {{data.name}}
                                                </option>
                                            </select> -->

                                            <multiselect 
                                                v-model="selectedAdmin"
                                                :custom-label="customLabel"
                                                track-by="code" 
                                                :placeholder="'Search Code/Name of Admin'"
                                                open-direction="bottom" 
                                                :options="options.admins" 
                                                :multiple="false" 
                                                :searchable="true" 
                                                :loading="isLoading" 
                                                :internal-search="false" 
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :max-height="600" 
                                                :show-no-results="false" 
                                                :hide-selected="false" 
                                                @search-change="getAdmin">
                                                <span slot="noResult">Oops! No Data found.</span>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_agent'">
                                        <div class="form-group">
                                            <label>Agen</label>
                                            <!-- <select name="" id="" class="form-control" v-model="filterby.id_agent">
                                                <option value="">Pilih Agen</option>
                                                <option v-for="(data, index) in options.agents" :key="index" :value="data.id">
                                                    {{data.code}} | {{data.name}}
                                                </option>
                                            </select> -->

                                            <multiselect 
                                                v-model="selectedAgent"
                                                :custom-label="customLabel"
                                                track-by="code" 
                                                :placeholder="'Search Code/Name of Agent'"
                                                open-direction="bottom" 
                                                :options="options.agents" 
                                                :multiple="false" 
                                                :searchable="true" 
                                                :loading="isLoading" 
                                                :internal-search="false" 
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :max-height="600" 
                                                :show-no-results="false" 
                                                :hide-selected="false" 
                                                @search-change="getAgen">
                                                <span slot="noResult">Oops! No Data found.</span>
                                            </multiselect>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-2 align-self-center">
                                <button type="button" class="btn btn-sm rounded btn-danger" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                    <i class="fa fa-times"></i>
                                </button>&nbsp;
                                <button type="button" class="btn btn-sm rounded btn-primary" @click="addFilter()" v-if="idx == filter.length - 1">
                                    <i class="fa fa-plus"></i>
                                </button> 
                                
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList(), totalDiamondTerjual()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
// import { debounce } from "debounce";

export default {
	components: {
        Table
	},
    props: [
        'semua',
        'hanya_saya',
        'bawahan',
    ],
	data() {
		return {
			table: {
                title: '',
                dataTable: {
                    columns: ['counter', 'created_at', 'total_diamond', 'admin_code', 'agent_code'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            currentSort: 'created_at',
            currentSortDir: 'desc',
            filterby: {
                tgl_awal: '',
                tgl_akhir: '',
                id_customer: '',
                id_product: '',
                id_admin: '',
                id_agent: '',
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Tanggal Awal',
                    'by': 'tgl_awal'
                },
                {
                    'name': 'Tanggal Akhir',
                    'by': 'tgl_akhir'
                },
                {
                    'name': 'ID Bigo',
                    'by': 'id_customer'
                },
                {
                    'name': 'Product',
                    'by': 'id_product'
                },
                {
                    'name': 'Admin',
                    'by': 'id_admin'
                },
                {
                    'name': 'Agen',
                    'by': 'id_agent'
                }
            ],
            options: {
                customers: [],
                products: [],
                admins: [],
                agents: []
            },
            selectedCustomer: '',
            selectedProduct: '',
            selectedAdmin: '',
            selectedAgent: '',
            totalDiamond: 0
		}
	},
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/transaction?key=' + _.search + '&page=' + page + '&limit=' + _.limit + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir
                    + '&tgl_awal='      + _.filterby.tgl_awal
                    + '&tgl_akhir='     + _.filterby.tgl_akhir
                    + '&id_customer='   + _.filterby.id_customer
                    + '&id_admin='      + _.filterby.id_admin
                    + '&id_product='    + _.filterby.id_product
                    + '&id_agent='      + _.filterby.id_agent
                    
                    + '&semua='             + _.semua
                    + '&hanya_saya='        + _.hanya_saya
                    + '&bawahan_id_agent='  + _.bawahan
                    
                )
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getCustomer(search){
            let _ = this
            _.axios.get('/customer?page=1&limit=10&key=' + search)
                .then(resp=>{
                    _.options.customers = resp.data.data.data
                })
        },
        getProduct(search){
            let _ = this
            _.axios.get('/product?page=1&limit=10&key=' + search)
                .then(resp=>{
                    let data = resp.data.data.data

                    _.options.products = []
                    _.options.products.push({
                        id: 'null',
                        name: 'Manual'
                    })
                    for (let index = 0; index < data.length; index++) {
                        _.options.products.push(data[index])
                    }
                })
        },
        getAdmin(search){
            let _ = this
            _.axios.get('/user?role=admin&page=1&limit=10&key=' + search)
                .then(resp=>{
                    _.options.admins = resp.data.data.data
                })
        },
        getAgen(search){
            let _ = this
            _.axios.get('/agent?page=1&limit=10&key=' + search)
                .then(resp=>{
                    _.options.agents = resp.data.data.data
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Penjualan?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/transaction/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        sortBy(x) {
            let _ = this
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            _.getList()
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'tgl_awal':
                    _.filterby.tgl_awal = ''
                    break;

                case 'tgl_akhir':
                    _.filterby.tgl_akhir = ''
                    break;           
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        },
        reset() {
            let _ = this 
            
            _.filterby.tgl_awal = ''
            _.filterby.tgl_akhir = ''
            _.filterby.id_customer = ''
            _.filterby.id_product = ''
            _.filterby.id_admin = ''
            _.filterby.id_agent = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        },
        download(){
            let _ = this
            _.isLoading = true
            _.axios.get('/transaction/export'
                    + '?tgl_awal='      + _.filterby.tgl_awal
                    + '&tgl_akhir='     + _.filterby.tgl_akhir
                    + '&id_customer='   + _.filterby.id_customer
                    + '&id_admin='      + _.filterby.id_admin
                    + '&id_product='    + _.filterby.id_product
                    + '&id_agent='      + _.filterby.id_agent
                    
                    + '&semua='             + _.semua
                    + '&hanya_saya='        + _.hanya_saya
                    + '&bawahan_id_agent='  + _.bawahan
                ,{ responseType: 'arraybuffer' })
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanPengeluaran-'+ _.$moment().format('DDMMYYYYhhmmss') +'.xlsx'
                    link.click()
                    _.isLoading = false
                })
                .catch(() => {
                    _.isLoading = false
                })
        },
        customLabel({ code }) {
            return `${code}`;
        },
        totalDiamondTerjual() {
            let _ = this
            _.axios.get('/transaction/total_diamond_terjual?key=' + _.search
                    + '&tgl_awal='      + _.filterby.tgl_awal
                    + '&tgl_akhir='     + _.filterby.tgl_akhir
                    + '&id_customer='   + _.filterby.id_customer
                    + '&id_admin='      + _.filterby.id_admin
                    + '&id_product='    + _.filterby.id_product
                    + '&id_agent='      + _.filterby.id_agent
                    
                    + '&semua='             + _.semua
                    + '&hanya_saya='        + _.hanya_saya
                    + '&bawahan_id_agent='  + _.bawahan
                )
                .then(resp=>{
                    _.totalDiamond = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
        limit: function() {
            this.getList()
        },
        selectedCustomer: function() {
            this.filterby.id_customer = this.selectedCustomer.id
        },
        selectedProduct: function() {
            this.filterby.id_product = this.selectedProduct.id
        },
        selectedAdmin: function() {
            this.filterby.id_admin = this.selectedAdmin.id
        },
        selectedAgent: function() {
            this.filterby.id_agent = this.selectedAgent.id
        },
    },
    mounted() {
        let _ = this
        _.getList()
        _.getCustomer('')
        _.getProduct('')
        _.getAdmin('')
        _.getAgen('')
        _.totalDiamondTerjual()

        // _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });

        if (localStorage.getItem('role') == 'admin') {
            _.filterOption = [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Tanggal Awal',
                    'by': 'tgl_awal'
                },
                {
                    'name': 'Tanggal Akhir',
                    'by': 'tgl_akhir'
                },
                {
                    'name': 'ID Bigo',
                    'by': 'id_customer'
                },
                {
                    'name': 'Product',
                    'by': 'id_product'
                },
                {
                    'name': 'Agen',
                    'by': 'id_agent'
                }
            ]
        }
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    }
}
</script>