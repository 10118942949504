export default {
	login({ commit }, payload) {
		commit('UPDATE_USER', payload)
	},
	tryAutoLogin({ commit }) {
		let updateUser = {
			user: {
				id		: localStorage.getItem('id'),
				name	: localStorage.getItem('name'),
				email	: localStorage.getItem('email'),
				role	: localStorage.getItem('role'),
				file_avatar	: localStorage.getItem('file_avatar'),
			},
			token: localStorage.getItem('token')						
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}