<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-2">Bawahan Lv. 1 
                            <div class="pull-right badge badge-sm badge-alert" v-if="bawahan_lv_1.length > 0">
                                {{ bawahan_lv_1.length }} Bawahan
                            </div>
                        </h3>
                        <div class="table-responsive" style="max-height: 500px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody v-if="bawahan_lv_1.length > 0">
                                    <tr v-for="(bawahan, index) in bawahan_lv_1" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ bawahan.name }}</td>
                                        <td>{{ bawahan.user ? bawahan.user.email : '-' }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3">Tidak ada data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-2">Bawahan Lv. 2 
                            <div class="pull-right badge badge-sm badge-alert" v-if="bawahan_lv_2.length > 0">
                                {{ bawahan_lv_2.length }} Bawahan
                            </div>
                        </h3>
                        <div class="table-responsive" style="max-height: 500px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody v-if="bawahan_lv_2.length > 0">
                                    <tr v-for="(bawahan, index) in bawahan_lv_2" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ bawahan.name }}</td>
                                        <td>{{ bawahan.user ? bawahan.user.email : '-' }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3">Tidak ada data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col">
                <div class="card">
                    <div class="card-body">
                        <h3 class="font-600 mb-2">Bawahan Lv. 3 
                            <div class="pull-right badge badge-sm badge-alert" v-if="bawahan_lv_3.length > 0">
                                {{ bawahan_lv_3.length }} Bawahan
                            </div>
                        </h3>
                        <div class="table-responsive" style="max-height: 500px;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Nama</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody v-if="bawahan_lv_3.length > 0">
                                    <tr v-for="(bawahan, index) in bawahan_lv_3" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ bawahan.name }}</td>
                                        <td>{{ bawahan.user.email }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3">Tidak ada data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body">
                <h3 class="font-600 mb-2">Pendapatan Bawahan</h3>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="offline-tab" data-toggle="tab" href="#offline" role="tab" aria-controls="offline" aria-selected="true"
                        @click="state = 'offline'">
                            Transaksi Offline
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="online-tab" data-toggle="tab" href="#online" role="tab" aria-controls="online" aria-selected="true"
                        @click="state = 'online'">
                            Transaksi Online
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="offline" role="tabpanel" aria-labelledby="offline-tab">
                        <TransaksiOffline :id_agent="$route.params.id" :bonus="detail.bonus_jaringan" v-if="state == 'offline'"/>
                    </div>
                    <div class="tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                        <TransaksiOnline :id_agent="$route.params.id" :bonus="detail.bonus_jaringan" v-if="state == 'online'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TransaksiOffline from '@/views/agen/TransaksiOffline.vue'
import TransaksiOnline from '@/views/agen/TransaksiOnline.vue'

export default {
    components: {
        TransaksiOffline,
        TransaksiOnline,
    },
    data() {
        return {
            bawahan_lv_1: [],
            bawahan_lv_2: [],
            bawahan_lv_3: [],
            detail: null,
            state: 'offline'
        }
    },
    methods: {
        getBawahan(level) {
            let _ = this

            _.axios.get('/agent/bawahan?id=' + _.$route.params.id + '&level=' + level + '&is_active=1')
                .then( resp => {
                    switch ( level ) {
                        case 'id_atasan_1':
                            _.bawahan_lv_1 = resp.data.data
                            break;
                        case 'id_atasan_2':
                            _.bawahan_lv_2 = resp.data.data
                            break;
                        case 'id_atasan_3':
                            _.bawahan_lv_3 = resp.data.data
                            break;
                    
                        default:
                            break;
                    }
                })
        },
        getAgentDetail()
        {
            let _ = this
            _.axios.get('/agent/' + _.$route.params.id)
                .then( resp => {
                    _.detail = resp.data.data
                })
        }
    },
    mounted() {
        let _ = this

        _.getBawahan('id_atasan_1')
        _.getBawahan('id_atasan_2')
        // _.getBawahan('id_atasan_3')

        _.getAgentDetail()
    },
}
</script>