<template>
	<div class="row">
		<div class="col-sm-12 col-md-7 align-self-center">
			<div class="col">
				<div class="row">
					<div class="col-lg-6 col-xs-12 mt-1">
						<div class="row">
							<div class="align-self-center mr-1">
								Data per halaman:
							</div>
							<div>
								<select v-model="limit" style="border: 0px; outline: 0px;">                        
									<option value="10">10</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
									<option value="1000">1000</option>
									<option value="0">Semua</option>
								</select>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-xs-12 p-0 mt-1">
						Menampilkan {{data.from ? data.from : 0}} - {{data.to ? data.to : 0}} dari {{data.total ? data.total : 0}} data
					</div>
				</div>
			</div>

		</div>
	    <div class="col-sm-12 col-md-5">
	        <div class="dataTables_paginate paging_simple_numbers">
				<!-- MOBILE ONLY -->
	            <ul class="pagination mb-0 justify-content-center mt-1 d-lg-none">
	                <li class="paginate_button page-item previous" :class="{ disabled: data.current_page <= 1 }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.current_page - 1)"></button>
	                </li>

					<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
						<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
					</li>

	                <li class="paginate_button page-item next" :class="{ disabled: data.current_page >= data.last_page }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.current_page + 1)"></button>
	                </li>
	            </ul>

				<!-- DESKTOP ONLY -->
				<div class="d-lg-block d-none">
					<ul class="pagination mb-0 pull-right mt-1">
						<li class="paginate_button page-item previous" :class="{ disabled: data.current_page <= 1 }">
							<button type="button" class="page-link" @click.prevent="changePage(data.current_page - 1)">Sebelumnya</button>
						</li>

						<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
							<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
						</li>

						<li class="paginate_button page-item next" :class="{ disabled: data.current_page >= data.last_page }">
							<button type="button" class="page-link" @click.prevent="changePage(data.current_page + 1)">Selanjutnya</button>
						</li>
					</ul>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script>
export default {
	props: [ 'data' ],
	data() {
		return {
			limit: 10
		}
	},
	methods: {
		isCurrentPage(page){
			return this.data.current_page === page
		},
		changePage(page) {
			if (page > this.data.last_page) {
				page = this.data.last_page;
			}
			this.data.current_page = page;
			this.$root.$emit('paginate', page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.current_page
			let last            = this.data.last_page
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	},
	watch: {
		limit: function(){
			let _ = this
			_.$root.$emit('changeLimit', _.limit)
		}
	}
}
</script>