<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Kontak</h3>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Nama</label>
                            <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan nama customer">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="no. telepon" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>No. Telepon</label>
                            <input type="number" v-model="model.phone_number" class="form-control" placeholder="Masukkan no. telepon">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="URL whatsapp" v-slot="{ errors }" rules="required">
                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                            <label>URL Whatsapp</label>
                            <input type="text" v-model="model.whatsapp_url" class="form-control" placeholder="Masukkan url whatsapp">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="slug" v-slot="{ errors }" rules="required|alpha_dash">
                        <label>Slug</label>
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                            <span class="input-group-prepend">
                                <div class="input-group-text">
                                    mrtopup.id/landing/
                                </div>
                            </span>
                            <input type="text" class="form-control" v-model="model.slug" placeholder="Masukkan slug">
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <ValidationProvider name="primary" v-slot="{ errors }" rules="required">
                        <div class="form-group mt-1" :class="{ 'validation-warning': errors[0] }">
                            <label>Primary</label>
                            <select name="" id="" class="form-control" v-model="model.is_primary">
                                <option :value="1">Yes</option>
                                <option :value="0">No</option>
                            </select>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-right mt-1">
                        <router-link to="/kontak" class="btn text-danger">Batal</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                is_primary: 0
            },
            isLoading: false,
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/contact/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/contact' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/kontak')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }
    }
}
</script>