<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Produk Online</h3>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="Foto" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Foto</label>
                            <div v-if="model.photo" class="text-center profile-avatar">
                                <img :src="model.photo" class="img-fluid rounded mt-1 mr-1 pointer" 
                                    alt="" style="width: 150px; height: 150px;"><br>
                                <div class="edit-avatar">
                                    <button 
                                        @click="model.photo = null"
                                        class="btn btn-rounded btn-danger" 
                                        title="Delete"
                                        type="button">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <label for="file-upload" class="pointer">
                                    <img src="/assets/img/upload_image.png" alt="" class="img-fluid">
                                </label>
                                <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)">
                            </div>
                            <input type="hidden" v-model="model.photo">
                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Nama</label>
                            <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan nama produk">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="diamond" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Jumlah Diamond</label>
                            <input type="number" v-model="model.diamond" class="form-control" placeholder="Masukkan jumlah diamond">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="poin" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Jumlah Diamond / 1 Poin</label>
                            <input type="number" v-model="model.point" class="form-control" placeholder="Masukkan jumlah poin">
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="harga" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Harga</label>
                            <vue-numeric v-model="model.price" class="form-control" placeholder="Masukkan harga" currency="Rp" separator="."></vue-numeric>
                            <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <div class="text-right mt-1">
                        <router-link to="/produk" class="btn text-danger">Batal</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                photo: null
            },
            isLoading: false,
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/online_product/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/online_product' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/produk-online')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.photo = resp.data.data.url
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }
    }
}
</script>