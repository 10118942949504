<template>
    <div>
        <Table 
            :dataTable="table.dataTable"
            :title="table.title">
            <template v-slot:header>
                <div class="row">
                    <div class="col-lg-8 col-xs-12">
                        
                    </div>
                    <div class="col mb-1">
                        <div class="input-group form-search-rounded">
                            <input class="form-control" v-model="search" type="search"
                                placeholder="Cari berdasarkan nama...">
                            <span class="input-group-append">
                                <div class="input-group-text bg-transparent">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>                
            </template>

            <template v-slot:thead>
                <thead>
                    <tr>
                        <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                    </tr>
                </thead>
            </template>

            <template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit" 
                        data-toggle="modal" data-target="#modal" @click="model = data">
                        <i class="fa fa-edit"></i>
                    </button>
                </div>
            </template> 
        </Table>

        <div class="modal fade text-left show" id="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">{{ model.id ? 'Edit' : 'Tambah' }} Social Media</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ValidationObserver class="card-content" ref="observer">
                            <form @submit.prevent="save()">
                                <ValidationProvider name="Nama" v-slot="{ errors }" rules="">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama</label>
                                        <input type="text" v-model="model.name" class="form-control" disabled>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="link" v-slot="{ errors }" rules="">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Link</label>
                                        <input type="text" v-model="model.link" class="form-control" placeholder="Masukkan link">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="visibility" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Visibility</label>
                                        <select v-model="model.visibility" class="form-control">
                                            <option value="visible">Visible</option>
                                            <option value="hidden">Hidden</option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <hr>
                                <div class="text-right mt-1">
                                    <button class="btn text-danger" data-dismiss="modal">Batal</button>
                                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                                        <div v-else>Simpan</div>
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
    components: {
        Table
	},
    data() {
        return {
            table: {
                title: '',
                dataTable: {
                    header: ['#', 'Nama', 'Link', 'Visibility', 'Aksi'],
                    columns: ['counter', 'name', 'link', 'visibility'],
                    lists: [],
                }
			},
            search: '',
            limit: 10,
            isLoading: false,
            model: {
                image: '',
                order: 1,
                title: '',
                description: '',
                visibility: 'visible'
            }
        }
    },
    mounted() {
        let _ = this
        _.index()
        _.index = debounce(_.index, 500)

        _.$root.$on("paginate", (data) => {
            _.index(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        index(page) {
            let _ = this
            _.axios.get('/social_media?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.model
                }

                _.axios.post('/social_media' + (_.model.id ? '/' + _.model.id : ''), data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        window.$('.close').click()
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    },
    watch: {
        search: function() {
            this.index()
        },
        limit: function(){
            this.index()
        }
    }
}
</script>