<template>
    <div>
        <ValidationObserver class="card-content" ref="observer">
            <form @submit.prevent="save()">
                <div class="form-group">
                    <ValidationProvider name="Foto" v-slot="{ errors }" rules="required">
                        <label for="">Foto</label>
                        <div class="text-center profile-avatar" v-if="model.payment_method.image">
                            <img :src="model.payment_method.image" class="img-fluid rounded mt-1 mr-1 pointer" 
                                alt="" style="width: 107px; height: 108px;"><br>
                            <div class="edit-avatar">
                                <button 
                                    @click="model.payment_method.image = null"
                                    class="btn btn-rounded btn-danger" 
                                    title="Delete"
                                    type="button">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <label for="file-upload" class="pointer" v-if="!model.payment_method.image">
                            <img src="/assets/img/upload_image.png" alt="" class="img-fluid mt-1">
                        </label>
                        <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)" v-if="!model.payment_method.image">
                        <input type="hidden" v-model="model.payment_method.image">
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <ValidationProvider name="Description" v-slot="{ errors }" rules="required">
                        <label for="" class="mt-1">Description</label>
                        <quill-editor v-model="model.payment_method.description" :options="editorOption"/>
                        <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>

                    <div class="row pl-1 mt-1">
                        <input type="checkbox" name="" class="mr-1" v-model="model.payment_method.cc"><label>Credit Card</label>
                    </div>
                    <div class="row pl-1 mt-1">
                        <input type="checkbox" name="" class="mr-1" v-model="model.payment_method.cash"><label>Cash</label>
                    </div>
                </div>
                <hr>
                <div class="text-right mt-1">
                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Simpan</div>
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            model: {
                payment_method: {
                    image: '',
                    description: ''
                }
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link']
                    ]
                }
            },
        }
    },
    mounted() {
        let _ = this
        _.index()
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/setting')
                .then(resp=>{
                    if ( resp.data.data.payment_method ) {
                        _.model.payment_method = resp.data.data.payment_method
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.payment_method.image = resp.data.data.url
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    payment_method: JSON.stringify(_.model.payment_method)
                }

                _.axios.post('/setting', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    }
}
</script>