var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12",staticStyle:{"background":"url('/assets/img/vuexy-login-bg.jpg')","min-height":"100vh","object-fit":"cover"}},[_c('div',{staticClass:"row pt-2 pb-2"},[_c('div',{staticClass:"col-lg-3 d-none d-lg-block"}),_c('div',{staticClass:"col bg-white p-2",staticStyle:{"border-radius":"10px"}},[_c('router-link',{attrs:{"to":"/login"}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" Login")]),_vm._m(0),_c('p',{staticClass:"mb-0 f-18 font-600"},[_vm._v("Daftar Sebagai Partner,")]),_c('p',{staticClass:"text-blue font-500 f-18"},[_vm._v("Silakan lengkapi data-data berikut:")]),(_vm.errors)?_c('div',{staticClass:"alert alert-danger"},[_c('ul',{staticClass:"mb-0"},_vm._l((_vm.errors),function(v,k){return _c('div',{key:k},_vm._l((v),function(error){return _c('li',{key:error,staticClass:"text-danger mb-0",domProps:{"innerHTML":_vm._s(error)}})}),0)}),0)]):_vm._e(),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-black f-12",class:_vm.state == 'data-diri' ? 'active' : ''},[_vm._v(" Data Diri ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-black f-12",class:_vm.state == 'data-bigo' ? 'active' : ''},[_vm._v(" Data Bigo ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link text-black f-12",class:_vm.state == 'data-bank' ? 'active' : ''},[_vm._v(" Data Bank ")])])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show",class:_vm.state == 'data-diri' ? 'active' : '',attrs:{"id":"data-diri","role":"tabpanel","aria-labelledby":"data-diri-tab"}},[_c('ValidationObserver',{ref:"diri",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama lengkap Anda"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Nama Lengkap")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"no. telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.phone_number),expression:"model.phone_number"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan no. telepon Anda"},domProps:{"value":(_vm.model.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "phone_number", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("No. Telepon")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan email Anda"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Email")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"Masukkan password Anda"},domProps:{"value":(_vm.model.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Password")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password confirmation","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password_confirmation),expression:"model.password_confirmation"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"Konfirmasi password Anda"},domProps:{"value":(_vm.model.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password_confirmation", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Konfirmasi Password")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{ref:"kode_diinginkan",attrs:{"name":"kode agen","rules":"required|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.code),expression:"model.code"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan kode agen Anda"},domProps:{"value":(_vm.model.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "code", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Kode Agen yang Diinginkan")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{ref:"kode_agen",attrs:{"name":"kode referral","rules":"required|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.referral_code),expression:"model.referral_code"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan kode referral Anda"},domProps:{"value":(_vm.model.referral_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "referral_code", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Kode Referral")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('div',{staticClass:"col p-0 text-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":invalid},on:{"click":function($event){_vm.state = 'data-bigo'}}},[_vm._v("Selanjutnya")])])],1)]}}])})],1),_c('div',{staticClass:"tab-pane fade show",class:_vm.state == 'data-bigo' ? 'active' : '',attrs:{"id":"data-bigo","role":"tabpanel","aria-labelledby":"data-bigo-tab"}},[_c('ValidationObserver',{ref:"bigo",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"idnbo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bigo_id),expression:"model.bigo_id"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan ID Bigo Anda"},domProps:{"value":(_vm.model.bigo_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bigo_id", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("ID Bigo")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"namabigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bigo_name),expression:"model.bigo_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama bigo Anda"},domProps:{"value":(_vm.model.bigo_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bigo_name", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Nama Bigo")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('div',{staticClass:"col p-0 text-right"},[_c('button',{staticClass:"btn btn-outline-primary float-left",attrs:{"type":"button"},on:{"click":function($event){_vm.state = 'data-diri'}}},[_vm._v("Sebelumnya")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":invalid},on:{"click":function($event){_vm.state = 'data-bank'}}},[_vm._v("Selanjutnya")])])],1)]}}])})],1),_c('div',{staticClass:"tab-pane fade show",class:_vm.state == 'data-bank' ? 'active' : '',attrs:{"id":"data-bank","role":"tabpanel","aria-labelledby":"data-bank-tab"}},[_c('ValidationObserver',{ref:"bank",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"Bank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank),expression:"model.bank"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.model.bank)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Nama Bank")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"no. rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank_no),expression:"model.bank_no"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan no. rekening Anda"},domProps:{"value":(_vm.model.bank_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank_no", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("No. Rekening")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"nama rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank_name),expression:"model.bank_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama rekening Anda"},domProps:{"value":(_vm.model.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank_name", $event.target.value)}}}),_c('label',{staticClass:"form-control-placeholder required"},[_vm._v("Nama Rekening")]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"terms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"row pl-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.terms),expression:"model.terms"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.terms)?_vm._i(_vm.model.terms,null)>-1:(_vm.model.terms)},on:{"change":function($event){var $$a=_vm.model.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "terms", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "terms", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "terms", $$c)}}}}),_c('label',{staticClass:"align-self-center ml-1",attrs:{"for":""}},[_vm._v(" Saya telah membaca syarat & ketentuan")])]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"col p-0 text-right mt-1"},[_c('button',{staticClass:"btn btn-outline-primary float-left",attrs:{"type":"button"},on:{"click":function($event){_vm.state = 'data-bigo'}}},[_vm._v("Sebelumnya")]),_c('button',{staticClass:"btn btn-primary waves-effect waves-light",attrs:{"type":"button","disabled":_vm.isLoading || invalid},on:{"click":function($event){return _vm.register()}}},[(_vm.isLoading)?_c('div',[_vm._v("Mendaftarkan..."),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Daftar Sekarang")])])])],1)]}}])})],1)])],1),_c('div',{staticClass:"col-lg-3 d-none d-lg-block"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-logo"},[_c('img',{staticClass:"img-fluid rounded",attrs:{"src":"/assets/img/logo.png","alt":""}})])}]

export { render, staticRenderFns }