<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Tambah'}} Admin</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li v-for="error in v" :key="error" class="text-danger" v-html="error"></li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="code" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Kode</label>
                            <input type="text" v-model="model.code" class="form-control" placeholder="Masukkan kode">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Email</label>
                            <input type="text" v-model="model.email" class="form-control" placeholder="Masukkan email">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>Nama</label>
                            <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan nama">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="no. telepon" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label>No. Telepon</label>
                            <input type="number" v-model="model.phone_number" class="form-control" placeholder="Masukkan no. telepon">
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <hr>
                    <ValidationProvider name="new password" :rules="'min:6' + (!$route.params.id ? '|required' : '')" v-slot="{ errors }">
                        <label for="">Password Baru</label>
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                            <input :type="show_new_password ? 'text' : 'password'" v-model="model.password" class="form-control border-right-0" placeholder="Masukkan password baru">
                            <span class="input-group-append">
                                <div class="input-group-text">
                                    <a href="javascript:;" @click="show_new_password = !show_new_password" class="text-blue">
                                        <i :class="show_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <ValidationProvider name="confirm new password" :rules="'confirmed:new password|min:6' + (!$route.params.id ? '|required' : '')" v-slot="{ errors }">
                        <label for="" class="mt-1">Konfirmasi Password Baru</label>
                        <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                            <input :type="show_conf_new_password ? 'text' : 'password'" v-model="model.conf_password" class="form-control border-right-0" placeholder="Konfirmasi password baru">
                            <span class="input-group-append">
                                <div class="input-group-text">
                                    <a href="javascript:;" @click="show_conf_new_password = !show_conf_new_password" class="text-blue">
                                        <i :class="show_conf_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                    </a>
                                </div>
                            </span> 
                        </div>
                        <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                    <p class="mt-1">
                        <small>
                            <b>Hint</b>: 
                            Kata sandi harus setidaknya 6 karakter. Untuk membuatnya lebih kuat, gunakan huruf besar dan kecil, angka, dan simbol seperti ! " ? $% ^ )
                        </small>
                    </p>
                    <hr>
                    <div class="text-right mt-1">
                        <router-link to="/admin" class="btn text-danger">Batal</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Simpan</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                role: 'admin'
            },
            isLoading: false,
            errors: null,
            show_new_password: false,
            show_conf_new_password: false
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/user/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/user' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/admin')
                    })
                    .catch(err => {
                        _.errors = err.response.data.errors
                        _.isLoading = false
                    })            
            }
        }
    },
    mounted() {
        let _ = this
        if (_.$route.params.id){
            _.get()
        }
    }
}
</script>