<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <ValidationObserver ref="observer">
                <form @submit.prevent="save()">
                    <h3 class="font-600 mb-2">Change Profile</h3>
                    <div class="row">
                        <div class="col-lg-4 col-xs-12 text-center mb-2">
                            <div class="profile-avatar">
                                <img :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                <div class="edit-avatar">
                                    <label for="file-upload" class="btn-edit">
                                        <i class="fa fa-pencil"></i>
                                    </label>
                                    <input id="file-upload" class="input-file-avatar" type="file" v-on:change="Addphoto">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Email</label>
                                    <input type="text" v-model="model.email" class="form-control" placeholder="Masukkan Email">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">Nama</label>
                                    <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan Nama">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="no. telp" v-slot="{ errors }">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="" class="f-14 text-secondary">No. Telepon</label>
                                    <input type="number" v-model="model.phone_number" class="form-control" placeholder="Masukkan No. Telepon">
                                    <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <hr>
                    <div class="text-right">
                        <router-link to="/" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                            <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>Save</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                avatar: null
            },
            temp_photo: null,
            isLoading: false
        }
    },
    mounted() {
        let _ = this
        _.me()
    },
    methods: {
        save() {
            let _ = this

            let FData =  new FormData()
            for (let i in _.model){
                FData.append(i, _.model[i])
            }

            _.isLoading = true
            _.axios.post('user/' + localStorage.getItem('id'), FData)
                .then(resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message,
                    })
                    _.me()
                    _.isLoading = false
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    });
                    _.isLoading = false
                })
        },
        me() {
            let _ = this
            _.axios.get('auth/me')
                .then(resp => {
                    _.model = resp.data.data
                    if (_.model.file_avatar) {
                        this.temp_photo = _.model.file_avatar
                    }
                    _.$root.$emit('changeProfile', _.model)
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    })
                })
        },
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.avatar    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        }
    }
}
</script>