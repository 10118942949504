var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card col-lg-6 col-xs-12 p-0"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v(_vm._s(_vm.$route.params.id ? 'Edit' : 'Tambah')+" Kontak")]),_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama customer"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"no. telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("No. Telepon")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.phone_number),expression:"model.phone_number"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan no. telepon"},domProps:{"value":(_vm.model.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "phone_number", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"URL whatsapp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("URL Whatsapp")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.whatsapp_url),expression:"model.whatsapp_url"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan url whatsapp"},domProps:{"value":(_vm.model.whatsapp_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "whatsapp_url", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"slug","rules":"required|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Slug")]),_c('div',{staticClass:"form-group input-group mb-0",class:{ 'validation-warning': errors[0] }},[_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(" mrtopup.id/landing/ ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.slug),expression:"model.slug"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan slug"},domProps:{"value":(_vm.model.slug)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "slug", $event.target.value)}}})]),(errors[0])?_c('small',{staticClass:"val-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('ValidationProvider',{attrs:{"name":"primary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mt-1",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Primary")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.is_primary),expression:"model.is_primary"}],staticClass:"form-control",attrs:{"name":"","id":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "is_primary", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":1}},[_vm._v("Yes")]),_c('option',{domProps:{"value":0}},[_vm._v("No")])]),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('router-link',{staticClass:"btn text-danger",attrs:{"to":"/kontak"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }