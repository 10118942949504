<template>
    <div>
        <ValidationObserver ref="observer">
            <form @submit.prevent="save()">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="font-600 mb-2">Data Diri</h3>
                                <ValidationProvider name="code" v-slot="{ errors }" rules="required|max:7">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Kode</label>
                                        <input type="text" v-model="model.code" class="form-control" placeholder="Masukkan kode">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="code_referral" v-slot="{ errors }" rules="">
                                    <!-- <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Kode Atasan</label>
                                        <input type="text" v-model="model.atasan_langsung.code" class="form-control" placeholder="Masukkan kode atasan">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div> -->
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Atasan</label>
                                        <multiselect 
                                            v-model="selectedAgent"
                                            :custom-label="customLabel"
                                            track-by="code" 
                                            placeholder="Cari nama/kode agen"
                                            :options="options.agents.filter(x => x.id != $route.params.id)" 
                                            :multiple="false" 
                                            :searchable="true" 
                                            :loading="isLoading" 
                                            :internal-search="false" 
                                            :clear-on-select="false" 
                                            :close-on-select="true" 
                                            :max-height="600" 
                                            :show-no-results="false" 
                                            :hide-selected="false" 
                                            @search-change="get_agents">
                                            <span slot="noResult">Oops! Data agen tidak ditemukan.</span>
                                        </multiselect>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama</label>
                                        <input type="text" v-model="model.name" class="form-control" placeholder="Masukkan nama">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="no. telepon" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>No. Telepon</label>
                                        <input type="number" v-model="model.phone_number" class="form-control" placeholder="Masukkan no. telepon">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Email</label>
                                        <input type="text" class="form-control" v-model="model.email" placeholder="Masukkan email">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Password" v-slot="{ errors }" rules="required" v-if="!$route.params.id">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label>Password</label>
                                        <input type="password" class="form-control" v-model="model.password">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="commission" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Komisi / Diamond</label>
                                        <vue-numeric v-model="model.commission" class="form-control" placeholder="Masukkan komisi" currency="Rp" separator="."></vue-numeric>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="bonus jaringan" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Bonus Jaringan</label>
                                        <vue-numeric v-model="model.bonus_jaringan" class="form-control" placeholder="Masukkan bonus" currency="Rp" separator="."></vue-numeric>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="is_active" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Status</label>
                                        <select name="" id="" class="form-control" v-model="model.is_active">
                                            <option :value="1">Aktif</option>
                                            <option :value="0">Tidak Aktif</option>
                                        </select>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="font-600 mb-2">Data Bigo</h3>
                                <ValidationProvider name="Bigo ID" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Bigo ID</label>
                                        <input type="text" class="form-control" v-model="model.bigo_id" placeholder="Masukkan Bigo ID">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Bigo Name" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Bigo Name</label>
                                        <input type="text" class="form-control" v-model="model.bigo_name" placeholder="Masukkan Nama Bigo">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <h3 class="font-600 mb-2">Data Bank</h3>
                                <ValidationProvider name="Bank" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Bank</label>
                                        <input type="text" class="form-control" v-model="model.bank" placeholder="Masukkan nama bank">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="No. Rekening" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>No. Rekening</label>
                                        <input type="text" class="form-control" v-model="model.bank_no" placeholder="Masukkan no. rekening">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Nama Rekening" v-slot="{ errors }" rules="required">
                                    <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                        <label>Nama Rekening</label>
                                        <input type="text" class="form-control" v-model="model.bank_name" placeholder="Masukkan nama rekening">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="text-right mt-1">
                    <router-link to="/agen" class="btn text-danger">Batal</router-link>
                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Simpan</div>
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                id_admin: localStorage.getItem('id'),
                atasan_langsung: {
                    code: ''
                }
            },
            isLoading: false,
            selectedAgent: "",
            options: {
                agents: []
            },
        }
    },
    methods: {
        customLabel({ code }) {
            return `${code}`;
        },
        get() {
            let _ = this
            _.axios.get('/agent/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    if ( _.model.user ) {
                        _.model.email = _.model.user.email
                    }

                    if ( _.model.atasan_langsung ) {
                        _.getDetailAtasan()
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getDetailAtasan() {
            let _ = this
            _.axios.get('/agent?id_user=' + _.model.atasan_langsung.id)
                .then(resp => {
                    _.selectedAgent = resp.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                if ( _.selectedAgent ) {
                    _.model.id_atasan_1 = _.selectedAgent.id_user
                } else {
                    _.model.id_atasan_1 = null
                }

                _.axios.post('/agent' + (_.$route.params.id ? '/' + _.$route.params.id : ''), _.model)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.$router.push('/agen')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
        get_agents(key) {
            let _ = this
            _.axios.get('/agent?key='+ (key ? key : '') + '&limit=10&page=1')
                .then(resp => {
                    _.options.agents = resp.data.data.data
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
    },
    mounted() {
        let _ = this
        _.get_agents()
        if (_.$route.params.id){
            _.get()
        }
    }
}
</script>