var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card col-lg-6 col-xs-12 p-0"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v(_vm._s(_vm.$route.params.id ? 'Edit' : 'Tambah')+" Produk")]),_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama produk"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"diamond","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Jumlah Diamond")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.diamond),expression:"model.diamond"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan jumlah diamond"},domProps:{"value":(_vm.model.diamond)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "diamond", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"poin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Jumlah Diamond / 1 Poin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.point),expression:"model.point"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan jumlah poin"},domProps:{"value":(_vm.model.point)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "point", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Harga")]),_c('vue-numeric',{staticClass:"form-control",attrs:{"placeholder":"Masukkan harga","currency":"Rp","separator":"."},model:{value:(_vm.model.price),callback:function ($$v) {_vm.$set(_vm.model, "price", $$v)},expression:"model.price"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('router-link',{staticClass:"btn text-danger",attrs:{"to":"/produk"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }