<template>
    <div class="col-12" style="background: url('/assets/img/vuexy-login-bg.jpg'); min-height: 100vh; object-fit: cover;">
        <div class="row pt-2 pb-2">
            <div class="col-lg-3 d-none d-lg-block"></div>
            <div class="col bg-white p-2" style="border-radius: 10px;">
                <router-link to="/login"><i class="fa fa-arrow-left"></i> Login</router-link>
                <div class="form-logo">
                    <img src='/assets/img/logo.png' class="img-fluid rounded" alt="">
                </div>

                <p class="mb-0 f-18 font-600">Daftar Sebagai Partner,</p>
                <p class="text-blue font-500 f-18">Silakan lengkapi data-data berikut:</p>

                <div class="alert alert-danger" v-if="errors">
                    <ul class="mb-0">
                        <div v-for="(v, k) in errors" :key="k">
                            <li v-for="error in v" :key="error" class="text-danger mb-0" v-html="error"></li>
                        </div>
                    </ul>
                </div>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12"
                            :class="state == 'data-diri' ? 'active' : ''">
                            Data Diri
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12"
                            :class="state == 'data-bigo' ? 'active' : ''">
                            Data Bigo
                        </a>
                    </li>
                    <li class="nav-item">
                        <a 
                            class="nav-link text-black f-12"
                            :class="state == 'data-bank' ? 'active' : ''">
                            Data Bank
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show" :class="state == 'data-diri' ? 'active' : ''" id="data-diri" role="tabpanel" aria-labelledby="data-diri-tab">
                        <ValidationObserver ref="diri" v-slot="{ invalid }">
                            <form class="mt-2">
                                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.name" placeholder="Masukkan nama lengkap Anda">
                                            <label class="form-control-placeholder required">Nama Lengkap</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="no. telepon" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.phone_number" placeholder="Masukkan no. telepon Anda">
                                            <label class="form-control-placeholder required">No. Telepon</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.email" placeholder="Masukkan email Anda">
                                            <label class="form-control-placeholder required">Email</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="password" rules="required|min:8" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="password" class="form-control" v-model="model.password" placeholder="Masukkan password Anda">
                                            <label class="form-control-placeholder required">Password</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="password confirmation" rules="required|min:8|confirmed:password" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="password" class="form-control" v-model="model.password_confirmation" placeholder="Konfirmasi password Anda">
                                            <label class="form-control-placeholder required">Konfirmasi Password</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="kode agen" ref="kode_diinginkan" rules="required|max:7" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.code" placeholder="Masukkan kode agen Anda">
                                            <label class="form-control-placeholder required">Kode Agen yang Diinginkan</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="kode referral" ref="kode_agen" rules="required|max:7" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.referral_code" placeholder="Masukkan kode referral Anda">
                                            <label class="form-control-placeholder required">Kode Referral</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <div class="col p-0 text-right">
                                    <button class="btn btn-primary" type="button" @click="state = 'data-bigo'" :disabled="invalid">Selanjutnya</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'data-bigo' ? 'active' : ''" id="data-bigo" role="tabpanel" aria-labelledby="data-bigo-tab">
                        <ValidationObserver ref="bigo" v-slot="{ invalid }">
                            <form class="mt-2">
                                <ValidationProvider name="idnbo" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.bigo_id" placeholder="Masukkan ID Bigo Anda">
                                            <label class="form-control-placeholder required">ID Bigo</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="namabigo" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.bigo_name" placeholder="Masukkan nama bigo Anda">
                                            <label class="form-control-placeholder required">Nama Bigo</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <div class="col p-0 text-right">
                                    <button class="btn btn-outline-primary float-left" type="button" @click="state = 'data-diri'">Sebelumnya</button>
                                    <button class="btn btn-primary" type="button" @click="state = 'data-bank'" :disabled="invalid">Selanjutnya</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="tab-pane fade show" :class="state == 'data-bank' ? 'active' : ''" id="data-bank" role="tabpanel" aria-labelledby="data-bank-tab">
                        <ValidationObserver ref="bank" v-slot="{ invalid }">
                            <form class="mt-2">
                                <ValidationProvider name="Bank" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <!-- <select class="form-control" v-model="model.bank">
                                                <option :value="null">Pilih Bank</option>
                                                <option value="BCA">BCA</option>
                                                <option value="BNI">BNI</option>
                                                <option value="Mandiri">Mandiri</option>
                                            </select> -->
                                            <input type="text" class="form-control" v-model="model.bank">
                                            <label class="form-control-placeholder required">Nama Bank</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="no. rekening" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.bank_no" placeholder="Masukkan no. rekening Anda">
                                            <label class="form-control-placeholder required">No. Rekening</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="nama rekening" rules="required" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <input type="text" class="form-control" v-model="model.bank_name" placeholder="Masukkan nama rekening Anda">
                                            <label class="form-control-placeholder required">Nama Rekening</label>
                                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="terms" rules="required" v-slot="{ errors }">
                                    <div class="row pl-2">
                                        <input type="checkbox" v-model="model.terms">
                                        <label for="" class="align-self-center ml-1"> Saya telah membaca syarat & ketentuan</label>
                                    </div>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </ValidationProvider>
                                <div class="col p-0 text-right mt-1">
                                    <button class="btn btn-outline-primary float-left" type="button" @click="state = 'data-bigo'">Sebelumnya</button>

                                    <button type="button" class="btn btn-primary waves-effect waves-light" @click="register()" :disabled="isLoading || invalid">
                                        <div v-if="isLoading">Mendaftarkan...<i class="fa fa-spinner fa-spin"></i></div>
                                        <div v-else>Daftar Sekarang</div>
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 d-none d-lg-block"></div>
        </div>
    </div> 
</template>

<script>
export default {
    data() {
        return {
            model: {
                name: null,
                phone_number: null,
                email: null,
                password: null,
                password_confirmation: null,
                code: null,
                referral_code: null,
                bigo_id: null,
                bigo_name: null,
                bank: null,
                bank_no: null,
                bank_name: null,
                terms: null,
                commision: 0
            },
            state: 'data-diri',
            isLoading: false,
            errors: null
        }
    },
	methods: {
        register() {
            let _ = this
            _.isLoading = true
            _.axios.post('/agent', _.model)
                .then( resp => {
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                    _.isLoading = false
                    _.$router.push('/login')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })

                    if ( err.response.data.errors ) {
                        _.errors = err.response.data.errors
                    } else if ( err.response.data.message ) {
                        _.errors = err.response.data.message
                    }
                    _.isLoading = false
                })
        },
        checkKodeDiinginkan() {
            let _ = this
            _.axios.get('/agent?code=' + _.model.code)
                .then( resp => {
                    if ( !resp.data.data ) {
                        _.$refs['kode_diinginkan'].applyResult({
                            errors: [],
                            valid: true,
                            failedRules: {}
                        });
                        return;
                    } else {
                        _.$refs['kode_diinginkan'].applyResult({
                            errors: ['Kode sudah digunakan'],
                            valid: false,
                            failedRules: {}
                        });
                        return;
                    }
                })
        },
        checkAgen() {
            let _ = this
            _.axios.get('/agent?code=' + _.model.referral_code)
                .then( resp => {
                    if ( !resp.data.data ) {
                        _.$refs['kode_agen'].applyResult({
                            errors: ['Kode Agen tidak ditemukan'],
                            valid: false,
                            failedRules: {}
                        });
                        return;
                    } else {
                        _.$refs['kode_agen'].applyResult({
                            errors: [],
                            valid: true,
                            failedRules: {}
                        });
                        return;
                    }
                })
        },
	},
    watch: {
        'model.referral_code': function()
        {
            this.checkAgen()
        },
        'model.code': function()
        {
            this.checkKodeDiinginkan()
        }
    }
}
</script>