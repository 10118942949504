var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"dataTable":_vm.table.dataTable,"title":_vm.table.title},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-xs-12"},[_c('div',{staticClass:"btn-responsive"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal"},on:{"click":function($event){return _vm.reset()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Tambah ")])])]),_c('div',{staticClass:"col mb-1"},[_c('div',{staticClass:"input-group form-search-rounded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Cari berdasarkan judul..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text bg-transparent"},[_c('i',{staticClass:"fa fa-search"})])])])])])]},proxy:true},{key:"thead",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.table.dataTable.header),function(data,index){return _c('th',{key:index},[_vm._v(_vm._s(data))])}),0)])]},proxy:true},{key:"action",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"btn-action-block"},[_c('button',{staticClass:"btn btn-rounded btn-warning waves-effect waves-light",attrs:{"type":"button","title":"Edit","data-toggle":"modal","data-target":"#modal"},on:{"click":function($event){_vm.model = data}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{staticClass:"btn btn-rounded btn-danger waves-effect waves-light",attrs:{"type":"button","title":"Hapus"},on:{"click":function($event){return _vm.destroy(data)}}},[_c('i',{staticClass:"fa fa-trash"})])])]}}])}),_c('div',{staticClass:"modal fade text-left show",attrs:{"id":"modal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-primary white"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"myModalLabel160"}},[_vm._v(_vm._s(_vm.model.id ? 'Edit' : 'Tambah')+" Carousel")]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationProvider',{attrs:{"name":"Foto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Foto / Video")]),(_vm.model.image)?_c('div',{staticClass:"text-center profile-avatar"},[(!_vm.isVideo(_vm.model.image))?_c('img',{staticClass:"img-fluid rounded mt-1 mr-1 pointer",staticStyle:{"width":"107px","height":"108px"},attrs:{"src":_vm.model.image,"alt":""}}):_c('span',[_c('a',{staticClass:"mr-4",attrs:{"href":_vm.model.image,"target":"_blank"}},[_vm._v("Video")])]),_c('br'),_c('div',{staticClass:"edit-avatar"},[_c('button',{staticClass:"btn btn-rounded btn-danger",attrs:{"title":"Delete","type":"button"},on:{"click":function($event){_vm.model.image = null}}},[_c('i',{staticClass:"fa fa-times"})])])]):_vm._e(),(!_vm.model.image)?_c('label',{staticClass:"pointer",attrs:{"for":"file-upload"}},[_c('img',{staticClass:"img-fluid mt-1",attrs:{"src":"/assets/img/upload_image.png","alt":""}})]):_vm._e(),(!_vm.model.image)?_c('input',{staticClass:"input-file-avatar d-none",attrs:{"id":"file-upload","type":"file"},on:{"change":function($event){return _vm.upload($event)}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.image),expression:"model.image"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.model.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "image", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Judul")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.title),expression:"model.title"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan judul banner"},domProps:{"value":(_vm.model.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "title", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Deskripsi","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Deskripsi")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-control",attrs:{"name":"","id":"","cols":"30","rows":"10","placeholder":"Masukkan deskripsi"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Urutan")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.order),expression:"model.order"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan urutan banner"},domProps:{"value":(_vm.model.order)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "order", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"visibility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Visibility")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.visibility),expression:"model.visibility"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "visibility", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"visible"}},[_vm._v("Visible")]),_c('option',{attrs:{"value":"hidden"}},[_vm._v("Hidden")])]),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('button',{staticClass:"btn text-danger",attrs:{"data-dismiss":"modal"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])])],1)])],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }