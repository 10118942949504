var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card col-lg-6 col-xs-12 p-0"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v(_vm._s(_vm.$route.params.id ? 'Edit' : 'Tambah')+" Penjualan")]),_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationProvider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Customer")]),_c('vue-autosuggest',{attrs:{"suggestions":_vm.options.customers,"input-props":{class: 'form-control', placeholder:'Cari nama/kode customer'}},on:{"selected":_vm.selectedCustomer,"input":_vm.get_customers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('div',{staticClass:"row"},[_c('span',{staticClass:"my-suggestion-item col m-0 align-self-center"},[_vm._v(_vm._s(suggestion.item.code))]),_c('span',{staticClass:"my-suggestion-item col m-0 align-self-center"},[_vm._v(_vm._s(suggestion.item.name))])])]}}],null,true),model:{value:(_vm.model.customer_code),callback:function ($$v) {_vm.$set(_vm.model, "customer_code", $$v)},expression:"model.customer_code"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.isAgen),expression:"model.isAgen"}],staticClass:"mb-1",attrs:{"type":"checkbox","name":"","id":""},domProps:{"checked":Array.isArray(_vm.model.isAgen)?_vm._i(_vm.model.isAgen,null)>-1:(_vm.model.isAgen)},on:{"change":[function($event){var $$a=_vm.model.isAgen,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "isAgen", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "isAgen", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "isAgen", $$c)}},function($event){_vm.selectedAgent = null}]}}),_vm._v(" Agen "),(_vm.model.isAgen)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Agen")]),_c('multiselect',{attrs:{"custom-label":_vm.customLabel,"track-by":"code","placeholder":"Cari nama/kode agen","options":_vm.options.agents,"multiple":false,"searchable":true,"loading":_vm.isLoading,"internal-search":false,"clear-on-select":false,"close-on-select":true,"max-height":600,"show-no-results":false,"hide-selected":false},on:{"search-change":_vm.get_agents},model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! Data agen tidak ditemukan.")])]),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,false,156011891)})],1),_c('div',{staticClass:"col-4 d-none"},[_c('ValidationProvider',{attrs:{"name":"commission","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Komisi")]),_c('vue-numeric',{staticClass:"form-control",attrs:{"placeholder":"Masukkan komisi","currency":"Rp","separator":"."},model:{value:(_vm.model.commission),callback:function ($$v) {_vm.$set(_vm.model, "commission", $$v)},expression:"model.commission"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,false,3935219392)})],1)]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Jenis Produk")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.isManual),expression:"model.isManual"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "isManual", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.selectedProduct = null}]}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Manual")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Paket")])]),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),(_vm.model.isManual == '0')?_c('ValidationProvider',{attrs:{"name":"produk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Produk")]),_c('multiselect',{attrs:{"track-by":"id","label":"name","placeholder":"Cari nama/kode produk","options":_vm.options.products,"multiple":false,"searchable":true,"loading":_vm.isLoading,"internal-search":false,"clear-on-select":false,"close-on-select":true,"max-height":600,"show-no-results":false,"hide-selected":false},on:{"search-change":_vm.get_products},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! Data produk tidak ditemukan.")])]),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,false,1806482615)}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"poin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Jumlah Diamond")]),_c('vue-numeric',{staticClass:"form-control",attrs:{"placeholder":"Masukkan diamond","currency":"","separator":".","disabled":_vm.model.isManual == '0'},model:{value:(_vm.model.total_diamond),callback:function ($$v) {_vm.$set(_vm.model, "total_diamond", $$v)},expression:"model.total_diamond"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"harga","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Harga")]),_c('vue-numeric',{staticClass:"form-control",attrs:{"placeholder":"Masukkan harga","currency":"Rp","separator":".","disabled":_vm.model.isManual == '0'},model:{value:(_vm.model.total_price),callback:function ($$v) {_vm.$set(_vm.model, "total_price", $$v)},expression:"model.total_price"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1)]),_c('ValidationProvider',{attrs:{"name":"keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Keterangan")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-control",attrs:{"name":"","id":"","cols":"30","rows":"3"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('router-link',{staticClass:"btn text-danger",attrs:{"to":"/penjualan"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }