import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

// import Undermaintenance from '../views/errors/503.vue'
// import NotFound from '../views/errors/404.vue'
import Forbidden from '../views/errors/403.vue'

// import Landing from '../views/Landing.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import EditProfile from '../views/user/EditProfile.vue'
import EditProfileAgen from '../views/agen/EditProfile.vue'
import ChangePassword from '../views/user/ChangePassword.vue'

import AdminIndex from '../views/admin/Index.vue'
import AdminForm from '../views/admin/Form.vue'

import AgenIndex from '../views/agen/Index.vue'
import AgenForm from '../views/agen/Form.vue'
import AgenDetail from '../views/agen/Detail.vue'

import CustomerIndex from '../views/customer/Index.vue'
import CustomerForm from '../views/customer/Form.vue'

import ProdukIndex from '../views/produk/Index.vue'
import ProdukForm from '../views/produk/Form.vue'

import PenjualanIndex from '../views/penjualan/Index.vue'
import PenjualanForm from '../views/penjualan/Form.vue'

import KontakIndex from '../views/kontak/Index.vue'
import KontakForm from '../views/kontak/Form.vue'

import PengaturanForm from '../views/pengaturan/Index.vue'

import ProdukOnlineIndex from '../views/produk-online/Index.vue'
import ProdukOnlineForm from '../views/produk-online/Form.vue'

import ProdukMerchandiseIndex from '../views/produk-merchandise/Index.vue'
import ProdukMerchandiseForm from '../views/produk-merchandise/Form.vue'

import PenjualanOnlineIndex from '../views/penjualan-online/Index.vue'
import PenjualanOnlineDetail from '../views/penjualan-online/Detail.vue'

import PenjualanMerchandiseIndex from '../views/penjualan-merchandise/Index.vue'
import PenjualanMerchandiseDetail from '../views/penjualan-merchandise/Detail.vue'

import DashboardAgent from '../views/agen/Dashboard.vue'
import BawahanAgent from '../views/agen/Bawahan.vue'

import EventIndex from '../views/event/Index.vue'
import EventForm from '../views/event/Form.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home,
                redirect: localStorage.getItem('role') == 'agent' ? '/dashboard_agent' : null
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile,
                meta: {
                    pageTitle: 'Edit Profile',
                    icon: 'person'
                }
            },
            {
                path: '/user/agen/edit_profile',
                name: 'EditProfileAgen',
                component: EditProfileAgen,
                meta: {
                    pageTitle: 'Edit Profile',
                    icon: 'person'
                }
            },
            {
                path: '/user/change_password',
                name: 'ChangePassword',
                component: ChangePassword,
                meta: {
                    pageTitle: 'Change Password',
                    icon: 'lock'
                }
            },
            {
                path: '/admin',
                name: 'AdminIndex',
                component: AdminIndex,
                meta: {
                    pageTitle: 'Admin',
                    icon: 'support_agent'
                }
            },
            {
                path: '/admin/add',
                name: 'AdminFormAdd',
                component: AdminForm,
                meta: {
                    pageTitle: 'Admin / Tambah',
                    icon: 'support_agent'
                }
            },
            {
                path: '/admin/edit/:id',
                name: 'AdminFormEdit',
                component: AdminForm,
                meta: {
                    pageTitle: 'Admin / Edit',
                    icon: 'support_agent'
                }
            },
            {
                path: '/agen',
                name: 'AgenIndex',
                component: AgenIndex,
                meta: {
                    pageTitle: 'Agen',
                    icon: 'people'
                }
            },
            {
                path: '/agen/add',
                name: 'AgenFormAdd',
                component: AgenForm,
                meta: {
                    pageTitle: 'Agen / Tambah',
                    icon: 'people'
                }
            },
            {
                path: '/agen/edit/:id',
                name: 'AgenFormEdit',
                component: AgenForm,
                meta: {
                    pageTitle: 'Agen / Edit',
                    icon: 'people'
                }
            },
            {
                path: '/agen/detail/:id',
                name: 'AgenDetail',
                component: AgenDetail,
                meta: {
                    pageTitle: 'Agen / Detail',
                    icon: 'people'
                }
            },
            {
                path: '/customer',
                name: 'CustomerIndex',
                component: CustomerIndex,
                meta: {
                    pageTitle: 'Customer',
                    icon: 'people'
                }
            },
            {
                path: '/customer/add',
                name: 'CustomerFormAdd',
                component: CustomerForm,
                meta: {
                    pageTitle: 'Customer / Tambah',
                    icon: 'people'
                }
            },
            {
                path: '/customer/edit/:id',
                name: 'CustomerFormEdit',
                component: CustomerForm,
                meta: {
                    pageTitle: 'Customer / Edit',
                    icon: 'people'
                }
            },
            {
                path: '/produk',
                name: 'ProdukIndex',
                component: ProdukIndex,
                meta: {
                    pageTitle: 'Produk',
                    icon: 'category'
                }
            },
            {
                path: '/produk/add',
                name: 'ProdukFormAdd',
                component: ProdukForm,
                meta: {
                    pageTitle: 'Produk / Tambah',
                    icon: 'category'
                }
            },
            {
                path: '/produk/edit/:id',
                name: 'ProdukFormEdit',
                component: ProdukForm,
                meta: {
                    pageTitle: 'Produk / Edit',
                    icon: 'category'
                }
            },
            {
                path: '/produk-online',
                name: 'ProdukOnlineIndex',
                component: ProdukOnlineIndex,
                meta: {
                    pageTitle: 'Produk Online',
                    icon: 'category'
                }
            },
            {
                path: '/produk-online/add',
                name: 'ProdukOnlineFormAdd',
                component: ProdukOnlineForm,
                meta: {
                    pageTitle: 'Produk Online / Tambah',
                    icon: 'category'
                }
            },
            {
                path: '/produk-online/edit/:id',
                name: 'ProdukOnlineFormEdit',
                component: ProdukOnlineForm,
                meta: {
                    pageTitle: 'Produk Online / Edit',
                    icon: 'category'
                }
            },
            {
                path: '/produk-merchandise',
                name: 'ProdukMerchandiseIndex',
                component: ProdukMerchandiseIndex,
                meta: {
                    pageTitle: 'Produk Merchandise',
                    icon: 'category'
                }
            },
            {
                path: '/produk-merchandise/add',
                name: 'ProdukMerchandiseFormAdd',
                component: ProdukMerchandiseForm,
                meta: {
                    pageTitle: 'Produk Merchandise / Tambah',
                    icon: 'category'
                }
            },
            {
                path: '/produk-merchandise/edit/:id',
                name: 'ProdukMerchandiseFormEdit',
                component: ProdukMerchandiseForm,
                meta: {
                    pageTitle: 'Produk Merchandise / Edit',
                    icon: 'category'
                }
            },
            {
                path: '/penjualan',
                name: 'PenjualanIndex',
                component: PenjualanIndex,
                meta: {
                    pageTitle: 'Penjualan',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan/add',
                name: 'PenjualanFormAdd',
                component: PenjualanForm,
                meta: {
                    pageTitle: 'Penjualan / Tambah',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan/edit/:id',
                name: 'PenjualanFormEdit',
                component: PenjualanForm,
                meta: {
                    pageTitle: 'Penjualan / Edit',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan-online',
                name: 'PenjualanOnlineIndex',
                component: PenjualanOnlineIndex,
                meta: {
                    pageTitle: 'Penjualan / Penjualan Online',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan-online/detail/:id',
                name: 'PenjualanOnlineDetail',
                component: PenjualanOnlineDetail,
                meta: {
                    pageTitle: 'Penjualan / Penjualan Online / Detail',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan-merchandise',
                name: 'PenjualanMerchandiseIndex',
                component: PenjualanMerchandiseIndex,
                meta: {
                    pageTitle: 'Penjualan / Penjualan Merchandise',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/penjualan-merchandise/detail/:id',
                name: 'PenjualanMerchandiseDetail',
                component: PenjualanMerchandiseDetail,
                meta: {
                    pageTitle: 'Penjualan / Penjualan Merchandise / Detail',
                    icon: 'shopping_cart'
                }
            },
            {
                path: '/kontak',
                name: 'KontakIndex',
                component: KontakIndex,
                meta: {
                    pageTitle: 'Kontak',
                    icon: 'contact_phone'
                }
            },
            {
                path: '/kontak/add',
                name: 'KontakFormAdd',
                component: KontakForm,
                meta: {
                    pageTitle: 'Kontak',
                    icon: 'contact_phone'
                }
            },
            {
                path: '/kontak/edit/:id',
                name: 'KontakFormEdit',
                component: KontakForm,
                meta: {
                    pageTitle: 'Kontak',
                    icon: 'contact_phone'
                }
            },
            {
                path: '/setting',
                name: 'PengaturanForm',
                component: PengaturanForm,
                meta: {
                    pageTitle: 'Pengaturan',
                    icon: 'settings'
                }
            },
            {
                path: '/dashboard_agent',
                name: 'DashboardAgent',
                component: DashboardAgent,
            },
            {
                path: '/bawahan',
                name: 'BawahanAgent',
                component: BawahanAgent,
                meta: {
                    pageTitle: 'Agen',
                    icon: 'people'
                }
            },
            {
                path: '/event',
                name: 'EventIndex',
                component: EventIndex,
                meta: {
                    pageTitle: 'Event',
                    icon: 'ad_units'
                }
            },
            {
                path: '/event/add',
                name: 'EventFormAdd',
                component: EventForm,
                meta: {
                    pageTitle: 'Event',
                    icon: 'ad_units'
                }
            },
            {
                path: '/event/edit/:id',
                name: 'EventFormEdit',
                component: EventForm,
                meta: {
                    pageTitle: 'Event',
                    icon: 'ad_units'
                }
            },
            {
                path: '/403',
                name: 'Forbidden',
                component: Forbidden
            }
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/register',
                name: 'Register',
                component: Register,
                meta: {
                    isGuest: true
                }
            },
            // {
            //     path: '/landing',
            //     name: 'Landing',
            //     component: Landing,
            //     meta: {
            //         isGuest: true
            //     }
            // },
            // {
            //     path: '/landing/:slug',
            //     name: 'Landing',
            //     component: Landing,
            //     meta: {
            //         isGuest: true
            //     }
            // },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Login'})
    } else if (to.meta.roles){
        let isThere     = to.meta.roles.filter(x => x == localStorage.getItem('role'))
        let isAllowed   = false
        
        if (isThere.length > 0) {
            isAllowed = true;
        }
        
        if (isAllowed){
            next()
        } else{
            next({name: 'Forbidden'})
        }
    } else {
        next()
    }
})

export default router
