<template>
    <div class="card col-lg-6 col-xs-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">Change Password</h3>
            <div class="row">
                <div class="col-lg-2 col-xs-12 text-center mb-1">
                    <button class="btn btn-rounded btn-primary p-1">
                        <span class="material-icons" style="font-size: 35px;">lock</span>
                    </button>
                </div>
                <div class="col align-self-center">
                    <p class="f-14">To protect the security of your account, enter your current password. New password will be used to login, please enter at least 6 characters</p>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">
                    <ValidationObserver ref="observer2">
                        <form @submit.prevent="isChange()">
                            <ValidationProvider name="current password" rules="required|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_old_password ? 'text' : 'password'" v-model="model.old_password" class="form-control border-right-0" placeholder="Enter Current Password">
                                    <label class="form-control-placeholder" for="password">CURRENT PASSWORD</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_old_password = !show_old_password" class="text-blue">
                                                <i :class="show_old_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <hr>
                            <ValidationProvider name="new password" rules="required|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_new_password ? 'text' : 'password'" v-model="model.new_password" class="form-control border-right-0" placeholder="Enter New Password" ref="New password">
                                    <label class="form-control-placeholder" for="password">NEW PASSWORD</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_new_password = !show_new_password" class="text-blue">
                                                <i :class="show_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <ValidationProvider name="Confirm new password" rules="required|confirmed:new password|min:6" v-slot="{ errors }">
                                <div class="form-group input-group mb-0 mt-2" :class="{ 'validation-warning': errors[0] }">                            
                                    <input :type="show_conf_new_password ? 'text' : 'password'" v-model="model.conf_new_password" class="form-control border-right-0" placeholder="Enter New Password Confirmation" data-vv-as="New password">
                                    <label class="form-control-placeholder" for="password">NEW PASSWORD CONFIRMATION</label>
                                    <span class="input-group-append">
                                        <div class="input-group-text">
                                            <a href="javascript:;" @click="show_conf_new_password = !show_conf_new_password" class="text-blue">
                                                <i :class="show_conf_new_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                            </a>
                                        </div>
                                    </span> 
                                </div>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </ValidationProvider>
                            <p class="mt-1"><small><b>Hint</b>: The password should be at least 6 characters long. To make it stronger, use upper and lower case letters, numbers, and symbol like ! " ? $ % ^ )</small></p>
                            <hr>
                            <div class="text-right mt-1">
                                <router-link to="/" class="btn text-danger">Cancel</router-link>
                                <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                                    <div v-if="isLoading">Saving <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Save</div>
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                old_password: null,
                new_password: null,
                conf_new_password: null
            },
            show_old_password: false,
            show_new_password: false,
            show_conf_new_password: false,
            isLoading: false,
            state: 2
        }
    },
    methods: {
        save() {
            let _ = this
            _.isLoading = true
            _.axios.post('auth/change_password', _.model)
                .then(resp => {
                    _.$swal.fire({
                        title: resp.data.message,
                        text: 'Silahkan Login Kembali',
                        icon: 'success',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    })
                    
                    _.$store.dispatch('logout')
                    window.location = '/login'
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    })
                    _.isLoading = false
                })
        },
        async isChange(){
            let _ = this
            const isValid = await _.$refs.observer2.validate()
            if (isValid){
                _.$swal.fire({
                    title: 'Are you sure you want to change your password?',
                    html: '<p class="popup-text-normal">You will be logged out automatically when you change your password.</p>',
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'Cancel',
                    confirmButtonColor: '#0036A0',
                    confirmButtonText: 'Continue',
                    }).then((result) => {
                        if (result.value) {
                            _.save()
                        }
                    })
            } else {
                _.$notify({
                    type: 'warn',
                    title: 'Warning!',
                    text: 'Please fill the requirements',
                })
            }
        }
    }
}
</script>