var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"dataTable":_vm.table.dataTable,"title":_vm.table.title},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-xs-12"},[_c('div',{staticClass:"btn-responsive"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal"},on:{"click":function($event){return _vm.reset()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Tambah ")])])]),_c('div',{staticClass:"col mb-1"},[_c('div',{staticClass:"input-group form-search-rounded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Cari berdasarkan judul..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text bg-transparent"},[_c('i',{staticClass:"fa fa-search"})])])])])])]},proxy:true},{key:"thead",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.table.dataTable.header),function(data,index){return _c('th',{key:index},[_vm._v(_vm._s(data))])}),0)])]},proxy:true},{key:"action",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"btn-action-block"},[_c('button',{staticClass:"btn btn-rounded btn-warning waves-effect waves-light",attrs:{"type":"button","title":"Edit","data-toggle":"modal","data-target":"#modal"},on:{"click":function($event){_vm.model = data}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('button',{staticClass:"btn btn-rounded btn-danger waves-effect waves-light",attrs:{"type":"button","title":"Hapus"},on:{"click":function($event){return _vm.destroy(data)}}},[_c('i',{staticClass:"fa fa-trash"})])])]}}])}),_c('div',{staticClass:"modal fade text-left show",attrs:{"id":"modal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-primary white"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"myModalLabel160"}},[_vm._v(_vm._s(_vm.model.id ? 'Edit' : 'Tambah')+" FAQ")]),_vm._m(0)]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",staticClass:"card-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationProvider',{attrs:{"name":"Pertanyaan","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Pertanyaan")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.question),expression:"model.question"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan pertanyaan"},domProps:{"value":(_vm.model.question)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "question", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Jawaban","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Jawaban")]),_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.model.answer),callback:function ($$v) {_vm.$set(_vm.model, "answer", $$v)},expression:"model.answer"}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Urutan")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.order),expression:"model.order"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan urutan"},domProps:{"value":(_vm.model.order)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "order", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('button',{staticClass:"btn text-danger",attrs:{"data-dismiss":"modal"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])])],1)])],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])}]

export { render, staticRenderFns }