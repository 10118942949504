import { ValidationProvider, extend } from 'vee-validate';
import { 
  required, 
  max, 
  max_value,
  min_value,
  email,
  min,
  confirmed,
  regex,
  alpha_dash
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This {_field_} field is required'
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'This {_field_} field must be at least {length} characters'
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'The maximum of this {_field_} field is {length} characters'
});

extend('max_value', {
  ...max_value,
  message: 'The {_field_} field must be {max} or less'
});

extend('min_value', {
  ...min_value,
  message: 'The {_field_} field must be {min} or more'
});

extend('email', {
  ...email,
  message: 'The {_field_} field must be an email'
});

extend('confirmed', {
  ...confirmed,
  message: 'Password confirmation does not match'
});

extend('regex', {
  ...regex,
  message: 'The {_field_} field is not valid'
});

extend('alpha_dash', {
  ...alpha_dash,
  message: 'The {_field_} must not contain spaces'
});

export default ValidationProvider