<template>
    <div>
        <div class="row mb-2">
            <div class="col align-self-center">
                <h3 class="font-600">{{ detail.name }} - {{ detail.code }}</h3>
            </div>
            <!-- <div class="col-4 text-right">
                <div class="row">
                    <div class="col align-self-center">
                        <span>Pilih Bulan</span>
                    </div>
                    <div class="col">
                        <select name="" id="" class="form-control">
                            <option value="">Januari 2022</option>
                        </select>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row row-cols-5 mb-2">
            <div class="col mb-1">
                <div class="card h-100 mb-0">
                    <div class="card-body text-center">
                        <h1 class="font-600">{{ dashboard.totalBawahanLevel1 | numFormat }} <i class="material-icons text-primary">people</i></h1>
                        <p class="mb-0">Bawahan:<br> Level 1</p>
                    </div>
                </div>
            </div>
            <div class="col mb-1">
                <div class="card h-100 mb-0">
                    <div class="card-body text-center">
                        <h1 class="font-600">{{ dashboard.totalBawahanLevel2 | numFormat }} <i class="material-icons text-primary">people</i></h1>
                        <p class="mb-0">Bawahan:<br> Level 2</p>
                    </div>
                </div>
            </div>
            <div class="col mb-1">
                <div class="card h-100 mb-0">
                    <div class="card-body text-center">
                        <h1 class="font-600">{{ dashboard.totalDiamondTerjualLevel1 | numFormat }} <i class="material-icons text-primary">diamond</i></h1>
                        <p class="mb-0">Diamond Terjual Bulan Ini: Level 1</p>
                    </div>
                </div>
            </div>
            <div class="col mb-1">
                <div class="card h-100 mb-0">
                    <div class="card-body text-center">
                        <h1 class="font-600">{{ dashboard.totalDiamondTerjualLevel2 | numFormat }} <i class="material-icons text-primary">diamond</i></h1>
                        <p class="mb-0">Diamond Terjual Bulan Ini: Level 2</p>
                    </div>
                </div>
            </div>
            <div class="col mb-1">
                <div class="card h-100 mb-0">
                    <div class="card-body text-center">
                        <h1 class="font-600">{{ dashboard.totalDiamondTerjual | numFormat }} <i class="material-icons text-primary">diamond</i></h1>
                        <p class="mb-0">Diamond Terjual Bulan Ini: {{ detail.code }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col">
                <div class="card mb-0">
                    <div class="card-body">
                        <h4 class="font-600">Grafik Penjualan Diamond Tahun {{ year }}</h4>
                        <div class="body-chart">
                            <apexchart type="bar" height="300" :options="penjualanOptions" :series="penjualanData" v-if="penjualanData[0].data || penjualanData[1].data || penjualanData[2].data"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="detail">
            <div class="card-body">
                <h4 class="font-600">Penjualan</h4>

                <button class="btn btn-sm font-400" :class="activeFilter == 'semua' ? 'btn-primary' : 'btn-outline-primary'" @click="activeFilter = 'semua'">Semua</button>&nbsp;
                <button class="btn btn-sm font-400" :class="activeFilter == 'hanya_saya' ? 'btn-primary' : 'btn-outline-primary'" @click="activeFilter = 'hanya_saya'">Hanya Saya</button>&nbsp;
                <button class="btn btn-sm font-400" :class="activeFilter == 'hanya_bawahan' ? 'btn-primary' : 'btn-outline-primary'" @click="activeFilter = 'hanya_bawahan'">Hanya Bawahan</button>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="offline-tab" data-toggle="tab" href="#offline" role="tab" aria-controls="offline" aria-selected="true"
                            @click="activeState = 'offline'">
                            Offline
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="online-tab" data-toggle="tab" href="#online" role="tab" aria-controls="online" aria-selected="true"
                            @click="activeState = 'online'">
                            Online
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="offline" role="tabpanel" aria-labelledby="offline-tab">
                        <TransaksiOffline v-if="show && activeState == 'offline'"
                            :semua="activeFilter == 'semua' ? detail.id : ''" 
                            :hanya_saya="activeFilter == 'hanya_saya' ? detail.id : ''"
                            :bawahan="activeFilter == 'hanya_bawahan' ? detail.id : ''"
                        />
                    </div>
                    <div class="tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                        <TransaksiOnline v-if="show && activeState == 'online'"
                            :semua="activeFilter == 'semua' ? detail.id : ''" 
                            :hanya_saya="activeFilter == 'hanya_saya' ? detail.id : ''"
                            :bawahan="activeFilter == 'hanya_bawahan' ? detail.id : ''"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TransaksiOffline from '@/views/agen/DashboardTransaksiOffline.vue'
import TransaksiOnline from '@/views/agen/DashboardTransaksiOnline.vue'

export default {
    components: {
        TransaksiOffline,
        TransaksiOnline,
    },
    data() {
        return {
            detail: null,
            dashboard: null,
            activeFilter: 'semua',
            activeState: 'offline',
            show: false,
            penjualanData: [
                {
                    name: 'Saya',
                    data: null
                },
                {
                    name: 'Bawahan Lv. 1',
                    data: null
                },
                {
                    name: 'Bawahan Lv. 2',
                    data: null
                }
            ],
            penjualanOptions: {
                chart: {
                    height: 300,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: ['#003046', '#FFB701', '#FB8501'],
                markers: {
                    size: 4,
                    colors: ["#003046", '#FFB701', '#FB8501'],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                },
                // dataLabels: {
                //     style: {
                //         colors: ['#000']
                //     }
                // }
            },
            year: this.$moment().format('YYYY')
        }
    },
    methods: {
        getAgentDetail()
        {
            let _ = this
            _.axios.get('/agent?id_user=' + localStorage.getItem('id'))
                .then( resp => {
                    _.detail = resp.data.data
                    _.show = true
                })
        },
        getDashboard() {
            let _ = this

            _.axios.get('/dashboard/agent?id=' + localStorage.getItem('id') )
                .then( resp => {
                    _.dashboard = resp.data.data
                })
        },
        getPenjualanYear(){
            let _ = this
            _.axios.get('/dashboard/agent/total_penjualan_tahunan?year='+ _.year +'&id=' + localStorage.getItem('id'))
                .then(resp=>{
                    let saya = []
                    let bawahan_lv_1 = []
                    let bawahan_lv_2 = []

                    resp.data.data.forEach(elm => {
                        saya.push(elm.saya)
                        bawahan_lv_1.push(elm.bawahan_lv_1)
                        bawahan_lv_2.push(elm.bawahan_lv_2)
                    });

                    _.penjualanData[0].data = saya
                    _.penjualanData[1].data = bawahan_lv_1
                    _.penjualanData[2].data = bawahan_lv_2
                })
        },
    },
    watch: {
        activeFilter: function()
        {
            this.show = false
            setTimeout(() => {
                this.show = true
            }, 100);
        }
    },
    mounted() {
        let _ = this
        _.getAgentDetail()
        _.getDashboard()
        _.getPenjualanYear()
        _.getPenjualanLv1Year()
        _.getPenjualanLv2Year()
    },
}
</script>
