<template>
    <section>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-6">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic pointer" @click="$router.push('/penjualan')">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <i class="material-icons mt-1 mb-1 text-primary" style="font-size: 50px;">monetization_on</i>
                                    </div>
                                    <div class="ic-title large">{{ total_pendapatan | currency }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate f-14" data-placement="bottom" data-original-title="Total Pendapatan">Total Pendapatan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic pointer" @click="$router.push('/penjualan')">
                                    <div class="ic-avatar ic-avatar-large ic-avatar-top">
                                        <i class="material-icons mt-1 mb-1 text-primary" style="font-size: 50px;">point_of_sale</i>
                                    </div>
                                    <div class="ic-title large">{{ total_diamond | numFormat }}</div>
                                    <p class="mr-auto ml-auto mb-0 line-ellipsis auto-truncate f-14" data-placement="bottom" data-original-title="Total Diamond">Total Diamond Terjual</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12">
                        <div class="card mb-2">
                            <div class="card-body ic-statistic d-flex align-items-start pointer" @click="$router.push('/customer')">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">groups</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">{{ total_customer | numFormat }}</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Total Customer">Total Customer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12">
                        <div class="card">
                            <div class="card-body ic-statistic d-flex align-items-start pointer" @click="$router.push('/agen')">
                                <div class="ic-avatar ic-left-block text-center">
                                    <i class="material-icons text-primary" style="font-size: 49px;">people</i>
                                </div>
                                <div class="ic-right-block">
                                    <div class="ic-title">{{ total_agen | numFormat }}</div>
                                    <p class="mb-0 line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Total Agen">Total Agen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-12">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic d-flex align-items-start">
                                    <div class="ic-avatar ic-left-block text-center">
                                        <i class="material-icons text-primary" style="font-size: 49px;">diamond</i>
                                    </div>
                                    <div class="ic-right-block text-left">
                                        <div class="ic-title">{{ saldo_diamond | numFormat }}</div>
                                        <p class="mb-0  line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Saldo Diamond Saat Ini">Saldo Diamond Saat Ini</p>
                                    </div>
                                    <button class="btn btn-primary btn-sm align-self-center" v-can="'spv'" data-toggle="modal" data-target="#settingSaldoDiamond">
                                        <i class="material-icons">settings</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic d-flex align-items-start">
                                    <div class="ic-avatar ic-left-block text-center">
                                        <i class="material-icons text-primary" style="font-size: 49px;">star</i>
                                    </div>
                                    <div class="ic-right-block text-left">
                                        <div class="ic-title">{{ point | numFormat }}</div>
                                        <p class="mb-0  line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Diamond / 1 Poin">Diamond / 1 Poin</p>
                                    </div>
                                    <button class="btn btn-primary btn-sm align-self-center" v-can="'spv'" data-toggle="modal" data-target="#settingPoint">
                                        <i class="material-icons">settings</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic d-flex align-items-start">
                                    <div class="ic-avatar ic-left-block text-center">
                                        <i class="material-icons text-primary" style="font-size: 49px;">price_change</i>
                                    </div>
                                    <div class="ic-right-block text-left">
                                        <div class="ic-title">{{ harga_acuan | currency }}</div>
                                        <p class="mb-0  line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Harga Acuan / 1 Diamond">Harga Acuan / 1 Diamond</p>
                                    </div>
                                    <button class="btn btn-primary btn-sm align-self-center" v-can="'spv'" data-toggle="modal" data-target="#settingHargaAcuan">
                                        <i class="material-icons">settings</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic d-flex align-items-start">
                                    <div class="ic-avatar ic-left-block text-center">
                                        <i class="material-icons text-primary" style="font-size: 49px;">paid</i>
                                    </div>
                                    <div class="ic-right-block text-left">
                                        <div class="ic-title">{{ komisi_rekrut | currency }}</div>
                                        <p class="mb-0  line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Komisi Penjualan">Komisi Penjualan</p>
                                    </div>
                                    <button class="btn btn-primary btn-sm align-self-center" v-can="'spv'" data-toggle="modal" data-target="#settingKomisiRekrut">
                                        <i class="material-icons">settings</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="card text-center">
                            <div class="card-content">
                                <div class="card-body ic-statistic d-flex align-items-start">
                                    <div class="ic-avatar ic-left-block text-center">
                                        <i class="material-icons text-primary" style="font-size: 49px;">paid</i>
                                    </div>
                                    <div class="ic-right-block text-left">
                                        <div class="ic-title">{{ bonus_jaringan | currency }}</div>
                                        <p class="mb-0  line-ellipsis auto-truncate" data-placement="bottom" data-original-title="Bonus Jaringan">Bonus Jaringan</p>
                                    </div>
                                    <button class="btn btn-primary btn-sm align-self-center" v-can="'spv'" data-toggle="modal" data-target="#settingBonusJaringan">
                                        <i class="material-icons">settings</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>        
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="card card-statistic">
                    <div class="card-header container">
                        <h5 class="card-title mb-1">Total Pendapatan Tahunan</h5>
                        <date-picker
                            v-model="filterPendapatan"
                            format="YYYY"
                            type="year"
                            value-type="YYYY"
                            class="pull-right col-lg-3 col-xs-12 p-0 text-right">
                        </date-picker>
                    </div>
                    <div class="card-content">
                        <div class="body-chart">
                            <apexchart type="bar" height="300" :options="pendapatanOptions" :series="pendapatanData" v-if="pendapatanData[0].data"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="card card-statistic">
                    <div class="card-header">
                        <h5 class="card-title mb-1">Total Diamond Tahunan</h5>
                        <date-picker
                            v-model="filterDiamond"
                            format="YYYY"
                            type="year"
                            value-type="YYYY"
                            class="pull-right col-lg-3 col-xs-12 p-0 text-right">
                        </date-picker>
                    </div>
                    <div class="card-content">
                        <div class="body-chart">
                            <apexchart type="bar" height="300" :options="diamondOptions" :series="diamondData" v-if="diamondData[0].data"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-left show" id="settingSaldoDiamond" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="saveDiamond()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Saldo Diamond</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-3">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required">
                                            <div class="form-group mb-0">
                                                <label>Tipe</label>
                                                <select class="form-control" v-model="diamond.type">
                                                    <option value="in">In</option>
                                                    <option value="out">Out</option>
                                                </select>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-9">
                                        <ValidationProvider name="jumlah" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Jumlah</label>
                                                <input type="text" class="form-control" placeholder="100" v-model="diamond.amount">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="diamond.amount = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left show" id="settingHargaAcuan" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="saveHargaAcuan()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Harga Acuan</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Harga Acuan</label>
                                                <vue-numeric v-model="set_harga_acuan.price" class="form-control" placeholder="Masukkan harga" currency="Rp" separator="."></vue-numeric>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="set_harga_acuan.price = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left show" id="settingPoint" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="savePoint()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Diamond Per Point</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Diamond Per Point</label>
                                                <input type="number" class="form-control" v-model="set_point.diamond_per_point">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="set_point.diamond_per_point = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left show" id="settingKomisiRekrut" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="saveKomisiRekrut()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Komisi Penjualan</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Komisi Penjualan</label>
                                                <vue-numeric v-model="set_komisi_rekrut.commission" class="form-control" placeholder="Masukkan komisi" currency="Rp" separator="."></vue-numeric>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="set_komisi_rekrut.price = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left show" id="settingBonusJaringan" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="saveBonusJaringan()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Pengaturan Bonus Jaringan</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="tipe" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Bonus Jaringan</label>
                                                <vue-numeric v-model="set_bonus_jaringan.bonus" class="form-control" placeholder="Masukkan bonus jaringan" currency="Rp" separator="."></vue-numeric>
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="set_bonus_jaringan.bonus = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            diamond: {
                type: 'in',
                amount: 0
            },
            set_harga_acuan:{
                price: 0
            },
            set_point: {
                diamond_per_point: 0
            },
            set_komisi_rekrut: {
                commission: 0
            },
            set_bonus_jaringan: {
                bonus: 0
            },
            pendapatanData: [
                {
                    name: 'Penjualan',
                    data: null
                }
            ],
            pendapatanOptions: {
                chart: {
                    height: 400,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: ['#f5982f'],
                markers: {
                    size: 4,
                    colors: ["#f5982f"],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                }
            },
            diamondData: [
                {
                    name: 'Diamond',
                    data: null
                }
            ],
            diamondOptions: {
                chart: {
                    height: 400,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                colors: ['#f5982f'],
                markers: {
                    size: 4,
                    colors: ["#f5982f"],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                }
            },
            filterPendapatan: '2022',
            filterDiamond: '2022',
            total_pendapatan: 0,
            total_diamond: 0,
            total_customer: 0,
            total_agen: 0,
            saldo_diamond: 0,
            harga_acuan: 0,
            point: 0,
            komisi_rekrut: 0,
            bonus_jaringan: 0
        }
    },
    methods: {
        getTotalPendapatan() {
            let _ = this
            _.axios.get('/dashboard/total_pendapatan')
                .then(resp=>{
                    _.total_pendapatan = resp.data.data
                })
        },
        getTotalDiamond() {
            let _ = this
            _.axios.get('/dashboard/total_diamond')
                .then(resp=>{
                    _.total_diamond = resp.data.data
                })
        },
        getTotalCustomer() {
            let _ = this
            _.axios.get('/dashboard/total_customer')
                .then(resp=>{
                    _.total_customer = resp.data.data
                })
        },
        getTotalAgen() {
            let _ = this
            _.axios.get('/dashboard/total_agen')
                .then(resp=>{
                    _.total_agen = resp.data.data
                })
        },
        getPendapatanYear(){
            let _ = this
            _.axios.get('/dashboard/total_pendapatan_tahunan?year=' + _.filterPendapatan)
                .then(resp=>{
                    _.pendapatanData[0].data = resp.data.data
                })
        },
        getDiamondYear(){
            let _ = this
            _.axios.get('/dashboard/total_diamond_tahunan?year=' + _.filterDiamond)
                .then(resp=>{
                    _.diamondData[0].data = resp.data.data
                })
        },
        getSaldoDiamond(){
            let _ = this
            _.axios.get('/diamond')
                .then(resp=>{
                    _.saldo_diamond = resp.data.data.balance
                })
        },
        saveDiamond(){
            let _ = this
            _.axios.post('/diamond', _.diamond)
                .then(resp=>{
                    _.saldo_diamond = resp.data.data.balance
                    $("#settingSaldoDiamond .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getHargaAcuan(){
            let _ = this
            _.axios.get('/harga_acuan')
                .then(resp=>{
                    _.harga_acuan = resp.data.data.price
                })
        },
        saveHargaAcuan(){
            let _ = this
            _.axios.post('/harga_acuan', _.set_harga_acuan)
                .then(resp=>{
                    _.harga_acuan = resp.data.data.price
                    $("#settingHargaAcuan .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getPoint(){
            let _ = this
            _.axios.get('/point')
                .then(resp=>{
                    _.point = resp.data.data.diamond_per_point
                })
        },
        savePoint(){
            let _ = this
            _.axios.post('/point', _.set_point)
                .then(resp=>{
                    _.point = resp.data.data.diamond_per_point
                    $("#settingPoint .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getKomisiRekrut(){
            let _ = this
            _.axios.get('/recruitment_commission')
                .then(resp=>{
                    _.komisi_rekrut = resp.data.data.commission
                })
        },
        saveKomisiRekrut(){
            let _ = this
            _.axios.post('/recruitment_commission', _.set_komisi_rekrut)
                .then(resp=>{
                    _.komisi_rekrut = resp.data.data.commission
                    $("#settingKomisiRekrut .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getBonusJaringan(){
            let _ = this
            _.axios.get('/bonus_jaringan')
                .then(resp=>{
                    _.bonus_jaringan = resp.data.data.bonus
                })
        },
        saveBonusJaringan(){
            let _ = this
            _.axios.post('/bonus_jaringan', _.set_bonus_jaringan)
                .then(resp=>{
                    _.bonus_jaringan = resp.data.data.bonus
                    $("#settingBonusJaringan .close").click()
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
    mounted() {
        let _ = this
        _.getTotalPendapatan()
        _.getTotalDiamond()
        _.getTotalCustomer()
        _.getTotalAgen()
        _.getPendapatanYear()
        _.getDiamondYear()
        _.getSaldoDiamond()
        _.getHargaAcuan()
        _.getPoint()
        _.getKomisiRekrut()
        _.getBonusJaringan()
    },
    watch: {
        filterPendapatan: function() {
            let _ = this
            _.pendapatanData[0].data = null
            this.getPendapatanYear()
        },
        filterDiamond: function() {
            let _ = this
            _.diamondData[0].data = null
            this.getDiamondYear()
        },
    }
}
</script>
