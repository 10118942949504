<template>
    <div class="card">
        <div class="card-body">
            <Table 
                :dataTable="table.dataTable"
                :title="table.title">
                <template v-slot:header>
                    <div class="row">
                        <div class="col-lg-8 col-xs-12">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary" @click="$router.push('/kontak/add')">
                                    <i class="fa fa-plus"></i> Tambah
                                </button>
                            </div>
                        </div>
                        <div class="col mb-1">
                            <div class="input-group form-search-rounded">
                                <input class="form-control" v-model="search" type="search"
                                    placeholder="Cari berdasarkan nama...">
                                <span class="input-group-append">
                                    <div class="input-group-text bg-transparent">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>                
                </template>

                <template v-slot:thead>
                    <thead>
                        <tr>
                            <th v-for="(data, index) in table.dataTable.header" :key="index">{{ data }}</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:action="{ data }">
                    <div class="btn-action-block">
                        <router-link :to="'/kontak/edit/' + data.id">
                            <button type="button" class="btn btn-rounded btn-warning waves-effect waves-light" title="Edit">
                                <i class="fa fa-edit"></i>
                            </button>
                        </router-link>
                        <button type="button" class="btn btn-rounded btn-danger waves-effect waves-light" title="Hapus" @click="destroy(data)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template> 
            </Table>
        </div>
        <div class="modal fade text-left show" id="redeemPoint" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ValidationObserver ref="observer">
                        <form @submit.prevent="redeemPoint()">
                            <div class="modal-header modal-primary white">
                                <h5 class="modal-title" id="myModalLabel160">Redeem Point</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="jumlah point" v-slot="{ errors }" rules="required|min_value:0">
                                            <div class="form-group mb-0">
                                                <label for="">Jumlah Poin</label>
                                                <input type="number" class="form-control" v-model="redeem_point.point_earned">
                                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="redeem_point.point_earned = 0">Reset</button>
                                <button type="submit" class="btn btn-primary waves-effect waves-light">Simpan</button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Table from '@/components/Table.vue'
import { debounce } from "debounce";

export default {
	components: {
        Table
	},
	data() {
		return {
			table: {
                title: 'Kontak',
                dataTable: {
                    header: ['#', 'Nama', 'No. Telepon', 'URL Whatsapp', 'Slug', 'Primary', 'Aksi'],
                    columns: ['counter', 'name', 'phone_number', 'whatsapp_url', 'slug', 'is_primary'],
                    lists: [],
                }
			},
            filter: '',
            search: '',
            limit: 10,
            isLoading: false,
            redeem_point: {
                id_customer: null,
                type: 'out',
                diamond: 0,
                point_earned: 0
            }
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/contact?key=' + _.search + '&page=' + page + '&limit=' + _.limit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin menghapus Customer?',
                text: x.name,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/contact/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        resetPoint(){
            let _ = this
            _.$swal.fire({
                title: 'Apa Anda yakin ingin mereset poin semua Customer?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#f5982f',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        _.axios.post('/customer/reset_poin')
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Reset poin berhasil!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#f5982f',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        redeemPoint(){
            let _ = this
            _.axios.post('/customer/redeem_poin', _.redeem_point)
                .then(()=>{
                    $("#redeemPoint .close").click()
                    _.getList()
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    watch: {
        search: function() {
            this.getList()
        },
        filter: function(){
            this.getList()
        },
        limit: function(){
            this.getList()
        }
    }
}
</script>