var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v("Data Diri")]),_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Kode")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.code),expression:"model.code"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan kode","disabled":""},domProps:{"value":(_vm.model.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "code", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('div',{staticClass:"form-group "},[_c('label',[_vm._v("Kode Atasan")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.atasan_langsung.code),expression:"model.atasan_langsung.code"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Tidak ada atasan","disabled":""},domProps:{"value":(_vm.model.atasan_langsung.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model.atasan_langsung, "code", $event.target.value)}}})]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"no. telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("No. Telepon")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.phone_number),expression:"model.phone_number"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"Masukkan no. telepon"},domProps:{"value":(_vm.model.phone_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "phone_number", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)])]),_c('div',{staticClass:"col-lg-6 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v("Data Bigo")]),_c('ValidationProvider',{attrs:{"name":"Bigo ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Bigo ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bigo_id),expression:"model.bigo_id"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Bigo ID"},domProps:{"value":(_vm.model.bigo_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bigo_id", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Bigo Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Bigo Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bigo_name),expression:"model.bigo_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan Nama Bigo"},domProps:{"value":(_vm.model.bigo_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bigo_name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"font-600 mb-2"},[_vm._v("Data Bank")]),_c('ValidationProvider',{attrs:{"name":"Bank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Bank")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank),expression:"model.bank"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama bank"},domProps:{"value":(_vm.model.bank)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"No. Rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("No. Rekening")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank_no),expression:"model.bank_no"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan no. rekening"},domProps:{"value":(_vm.model.bank_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank_no", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])}),_c('ValidationProvider',{attrs:{"name":"Nama Rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group ",class:{ 'validation-warning': errors[0] }},[_c('label',[_vm._v("Nama Rekening")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.bank_name),expression:"model.bank_name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Masukkan nama rekening"},domProps:{"value":(_vm.model.bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "bank_name", $event.target.value)}}}),(errors[0])?_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1)])])]),_c('hr'),_c('div',{staticClass:"text-right mt-1"},[_c('router-link',{staticClass:"btn text-danger",attrs:{"to":"/agen"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('div',[_vm._v("Menyimpan"),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Simpan")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }