<template>
    <div>
        <ValidationObserver class="card-content" ref="observer">
            <form @submit.prevent="save()">
                <ValidationProvider name="Foto" v-slot="{ errors }" rules="required">
                    <div class="text-center profile-avatar" v-if="model.logo">
                        <img :src="model.logo" class="img-fluid rounded mt-1 mr-1 pointer" 
                            alt="" style="width: 107px; height: 108px;"><br>
                        <div class="edit-avatar">
                            <button 
                                @click="model.logo = null"
                                class="btn btn-rounded btn-danger" 
                                title="Delete"
                                type="button">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </div>

                    <label for="file-upload" class="pointer" v-if="!model.logo">
                        <img src="/assets/img/upload_image.png" alt="" class="img-fluid mt-1">
                    </label>
                    <input id="file-upload" class="input-file-avatar d-none" type="file" @change="upload($event)" v-if="!model.logo">
                    <input type="hidden" v-model="model.logo">
                    <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                </ValidationProvider>
                <hr>
                <div class="text-right mt-1">
                    <button type="submit" class="btn btn-primary float-right" :disabled="isLoading">
                        <div v-if="isLoading">Menyimpan<i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Simpan</div>
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isLoading: false,
            model: {
                logo: ''
            }
        }
    },
    mounted() {
        let _ = this
        _.index()
    },
    methods: {
        index() {
            let _ = this
            _.axios.get('/setting')
                .then(resp=>{
                    _.model.logo = resp.data.data.logo
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        upload(event){
            let _ = this
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('file', file)

            _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                .then(resp => {
                    _.model.logo = resp.data.data.url
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true

                let data = {
                    ..._.model
                }

                _.axios.post('/setting', data)
                    .then(resp => {
                        _.$notify({
                            type: 'success',
                            title: 'Success!',
                            text: resp.data.message
                        })
                        _.index()
                        _.isLoading = false
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message
                        })
                        _.isLoading = false
                    })            
            }
        },
    }
}
</script>